import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import { FeedItem, FeedPageFooterData } from "../../../../../types/types";
import { TenantReducerState } from "../../../../../types/types";

import ItemHeader from "../itemHeader";
import PagesFooter from "./pagesFooter";

import renderHtml from "html-react-parser";

type PagesItemTypeProps = {
  isLastItem?: boolean;
  item: FeedItem;
  feedPageFooterData: FeedPageFooterData;
  setSharePage: Function;
  likePage: Function;
};

const PagesItemType: FunctionComponent<PagesItemTypeProps> = ({
  item,
  isLastItem,
  feedPageFooterData,
  setSharePage,
  likePage,
}) => {
  const { t } = useTranslation();
  const tenantInfo: TenantReducerState = useSelector(
    (state: any) => state.app.tenant
  );

  const getHeadText = () => {
    switch (item.reason) {
      case "comment_has_replies":
        return item.comment.last_reply.author.full_name;
      case "comment_has_likes":
        return item.comment.likes[0].author.full_name;
      default:
        if (item.type === "page") {
          return item.publisher.name;
        }

        return tenantInfo.title;
    }
  };

  const getHeaderTime = () => {
    switch (item.reason) {
      case "comment_has_replies":
        return item.comment.last_reply.updated_at_humans;
      case "comment_has_likes":
        return item.comment.last_like.created_at_humans;
      case "pns":
        return item.model.push_notification.sent_at_humans;
      default:
        if (item.type === "page") {
          return item.model.published_at;
        }

        return "";
    }
  };

  const renderBody = () => {
    const wrapperClasses = `media-body clearfix d-block ${
      item.model.pageUrl ? "c-pointer text-decoration-none" : ""
    }`;
    const wrapper = item.model.pageUrl ? (
      <a
        data-testid="item-link"
        className={wrapperClasses}
        href={`/page/${item.model.id}`}
        children={[]}
        onClick={pagesNavigation}
      />
    ) : (
      <div
        data-testid="item-link"
        children={[]}
        className={wrapperClasses}
        onClick={pagesNavigation}
      />
    );

    switch (item.reason) {
      case "comment_has_replies":
        wrapper.props.children.push([
          <React.Fragment>
            <h4
              data-testid="last-reply-comment-value"
              className="m-x-0 mb-0 text-light-black font-primary-text"
            >
              {t("feed.repliedToYourComment")}: {renderHtml(item.comment.last_reply.value)}
            </h4>
            <h4
              data-testid="comment-value"
              className="mh0 mb0 mt-3 text-normal font-secondary-text text-light-black grey-box p-2 original-comment"
            >
              {renderHtml(item.comment.value)}
            </h4>
          </React.Fragment>,
        ]);
        break;
      case "pns":
        wrapper.props.children.push([
          <React.Fragment>
            <h3
              data-testid="notification-title"
              className="m-0 mt-0 text-light-black font-primary-text"
            >
              {item.model.push_notification.title || ""}
            </h3>
            <h4
              data-testid="notification-message"
              className="m-x-0 mb-0 mt-3 fw-normal font-secondary-text text-light-black grey-box p-2"
            >
              {item.model.push_notification.message}
            </h4>
            <h4
              data-testid="preview-text"
              className="m-x-0 mb-0 mt-3 fw-normal font-secondary-text text-light-black border-top pt-3"
            >
              {item.model.preview_text || ""}
            </h4>
          </React.Fragment>,
        ]);
        break;
      default:
        wrapper.props.children.push([
          <React.Fragment>
            <h3
              data-testid="title"
              className="m-0 mt-0 text-light-black font-primary-text"
            >
              {item.model.title || ""}
            </h3>
            <h4
              data-testid="value"
              className="m-x-0 mb-0 mt-3 fw-normal font-secondary-text text-light-black"
            >
              {item.model.preview_text || ""}
            </h4>
          </React.Fragment>,
        ]);
    }

    return wrapper;
  };

  const pagesNavigation = (e: any) => {
    e.preventDefault();
    if (!item.model.pageUrl) return;
    if (
      item.pageType === 6 &&
      item.model.url &&
      !item.model.allow_comments &&
      !item.model.allow_likes &&
      !item.model.allow_share
    ) {
      window.open(item.model.url);
      return;
    }
    if (
      item.pageType === 1 &&
      item.model.url &&
      !item.model.allow_comments &&
      !item.model.allow_likes &&
      !item.model.allow_share
    ) {
      window.open(item.model.url);
      return;
    } else {
      window.location.href = `/page/${item.model.id}`;
    }
  };

  const getImgUrl = (item: FeedItem, imgUrl: string) => {
    let reason = item.reason.toLowerCase();
    switch (reason) {
      case "comment_has_replies":
        return item.comment.last_reply.author.image_url;
      case "comment_has_likes":
        return item.comment.likes[0].author.image_url;
      default:
        return imgUrl;
    }
  };

  const getImgRounded = (item: FeedItem) => {
    let reason = item.reason.toLowerCase();
    if (reason.startsWith("comment_has")) {
      return true;
    }
    return !item.publisher || item.publisher.rounded;
  };

  const getIconClass = (item: FeedItem) => {
    switch (item.reason.toLowerCase()) {
      case "pns":
        return {
          notificationIcon: "notification-icon",
          iconClass:
            "https://cdn.engageesp.com/img/feed/notification.png",
        };
      case "pinned":
        return {
          notificationIcon: "pinned-icon",
          iconClass:
            "https://cdn.engageesp.com/img/feed/pinned.png",
        };
      default:
        return {
          notificationIcon: "page-icon",
          iconClass: "https://cdn.engageesp.com/img/feed/page.png",
        };
    }
  };

  return (
    <Card
      className={`p-3 pb-2 item-container ${isLastItem ? "" : "mb-3"}`}
      style={{
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10)",
        borderRadius: "10px",
      }}
      data-testid="pages-item"
    >
      <ItemHeader
        imgUrl={getImgUrl(item, item.publisher.image)}
        isImgRound={getImgRounded(item)}
        headText={getHeadText()}
        jobTitle={item.model.jobtitle}
        className={getIconClass(item).notificationIcon}
        time={getHeaderTime()}
        contact_id={""}
        iconClass={getIconClass(item).iconClass}
      />

      <div className="media media-news mt-3">
        {item.model.preview_image && (
          <div
            className={`float-left d-flex justify-content-center align-items-flex-start mb-2 me-4 w-auto h-auto ${
              item.model.pageUrl ? "c-pointer" : ""
            }`}
            onClick={pagesNavigation}
          >
            <img
              src={item.model.preview_image}
              alt="Image"
              className="media-object preview-image"
              style={{ borderRadius: "4px", maxWidth: "200px" }}
            />
          </div>
        )}
        {renderBody()}
      </div>

      <PagesFooter
        item={item}
        feedPageFooterData={feedPageFooterData}
        setSharePage={setSharePage}
        likePage={likePage}
      />
    </Card>
  );
};

export default PagesItemType;
