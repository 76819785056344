export default {
  "please enter your company reference": "Voer uw bedrijfsreferentie in",
  "enter your company reference": "Voer uw bedrijfsreferentie in",
  "please enter your user ID and password": "Voer uw gebruikersnaam en wachtwoord in",
  "please enter user id": "Voer uw gebruikersnaam in",
  "enter password": "Voer uw wachtwoord in",
  "forgot password": "Wachtwoord vergeten",
  "errors": {
    "domain name can't be empty": "Domeinnaam mag niet leeg zijn",
    "user name can't be empty": "Gebruikersnaam mag niet leeg zijn",
    "password can't be empty": "Wachtwoord mag niet leeg zijn",
    "incorrect user id or password": "Onjuiste gebruikersnaam of wachtwoord",
    "domain does not exist": "Domein '{{domain}}' bestaat niet",
    "account locked": "Er zijn te veel pogingen gedaan, het account is nu vergrendeld"
  },
  "hello": "Hallo",
  "log in": "Inloggen",
  "new user": "Nieuwe gebruiker",
  "create new account": "Maak een nieuw account aan",
  "next": "Volgende",
  "verify": "VERIFIËREN",
  "contact support": "Contact opnemen met de klantenservice",
  "back": "TERUG"
};