import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { SideNavItem, BrandingReducerState } from "../../../../types/types";
import { useNavigate } from "react-router-dom";

type SideNavLinkProps = {
  item: SideNavItem;
  branding: BrandingReducerState;
  isActive: boolean;
  unreadThreads: number;
  isForMobileView?: boolean;
};

const SideNavLink: FunctionComponent<SideNavLinkProps> = ({
  item,
  isActive,
  branding,
  unreadThreads,
  isForMobileView,
}) => {
  const getBackColor = () => {
    return isActive
      ? branding.web_menubar_active_background
      : branding.web_menubar_background;
  };
  const navigate = useNavigate();

  const [backgroundColor, setBackgroundColor] = useState(getBackColor());
  const { route_url } = item;

  useEffect(() => {
    setBackgroundColor(getBackColor());
  }, [isActive, branding]);

  const iconContainerRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!iconContainerRef.current) return;
    const menuItemImages = iconContainerRef.current.querySelector("svg");
    if (!menuItemImages) return;
    // Clear all "fill" styles that exist
    menuItemImages.querySelectorAll("*").forEach((item) => {
      if (item.getAttribute("fill") !== "none") item.setAttribute("fill", "");
      if (item.getAttribute("stroke")) {
        item.classList.add("svg-stroke-el");
      }
    });
    menuItemImages.style.visibility = "visible";
  });

  const mouseOver = (e: React.MouseEvent) => {
    setBackgroundColor(branding.web_menubar_active_background);
  };
  const mouseLeave = (e: React.MouseEvent) => {
    setBackgroundColor(getBackColor());
  };

  const renderCustomIcon = () => {
    return { __html: item.custom_icon };
  };

  // Check if route path is static or page URL(start with "http")
  const isPageURL = (url: string) => {
    return url?.toLowerCase()?.includes("http") ?? false;
  };
  
  const shouldNavigateWindow = (url: string): boolean => {
    return url.startsWith("/page") || url.startsWith("/category") || url.startsWith("/custom")
  };

  const handleLinkClick = () => {
    if (shouldNavigateWindow(route_url)) {
      window.location.href = route_url;
    }
    if (isPageURL(route_url)) {
      window.location.replace(route_url);
    }
    
    navigate(route_url === '/messages' || route_url === '/chat' ? '/chat' : route_url)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      navigate(route_url === '/messages' || route_url === '/chat' ? '/chat' : route_url)
    }
  }
  
  return (
    <li className={`position-relative ${isForMobileView ? "mobile-view-menu" : ""}`} data-testid="side-nav-link">
      <a
        id={`menu-${item.id}`}
        data-testid="nav-link"
        onClick={handleLinkClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        className={`nav-link ${
          isActive ? "active" : ""
        } c-pointer d-block text-decoration-none pb-0`}
        style={{ backgroundColor: backgroundColor, padding: "8px 0px 0px 0px" }}
        target={isPageURL(route_url) ? "_blank" : "" } 
        rel="noopener noreferrer"
        onMouseEnter={mouseOver}
        onMouseLeave={mouseLeave}
      >
          <div className={"mb-1"}>
            {item.custom_icon ? (
              <div 
                data-testid="menu-icon"
                className={"menu-image"}
                ref={iconContainerRef}
                dangerouslySetInnerHTML={renderCustomIcon()}
              />
            ) : (
              <em className={item.icon_class} />
            )}
          </div>
          {item.is_visible_title ? (
            <span data-testid="menu-title">{item.title}</span>
          ) : (
            <div style={{ marginTop: "33px" }} />
          )}
          {unreadThreads !== 0 && (
            <span className="unread-threads-count">
            <i className="fa fa-circle font-22 unread-icon"></i>
            <span
              data-testid="unread-thread-count"
              className="unread-counter position-absolute fw-bold mx-0 d-flex justify-content-center align-items-center"
              style={{ color: backgroundColor }}
            >
                {unreadThreads}
              </span>
            </span>
          )}
        <hr className="my-0 mt-2 w-60 mx-auto" />
      </a>
    </li>
  );
};

export default SideNavLink;
