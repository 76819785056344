export default {
  "please enter your company reference": "Veuillez saisir la référence de votre entreprise",
  "enter your company reference": "Entrez la référence de votre entreprise",
  "please enter your user ID and password": "Veuillez saisir votre nom d'utilisateur et votre mot de passe",
  "please enter user id": "Veuillez saisir votre nom d'utilisateur",
  "enter password": "Veuillez saisir votre mot de passe",
  "forgot password": "Mot de passe oublié",
  "errors": {
    "domain name can't be empty": "Le nom de domaine ne peut pas être vide",
    "user name can't be empty": "Le nom d'utilisateur ne peut pas être vide",
    "password can't be empty": "Le mot de passe ne peut pas être vide",
    "incorrect user id or password": "Nom d'utilisateur ou mot de passe incorrect",
    "domain does not exist": "Le domaine « {{domain}} » n'existe pas",
    "account locked": "Il y a eu trop de tentatives, le compte est maintenant bloqué"
  },
  "hello": "Bonjour",
  "log in": "Connectez-vous",
  "new user": "Nouvel utilisateur",
  "create new account": "Créer un nouveau compte",
  "next": "Suivant",
  "verify": "VÉRIFIER",
  "contact support": "Contacter le support",
  "back": "RETOUR"
};