export default {
  "sharePost": "Begin met delen...",
  "what do you have to say": "Wat heb je te zeggen",
  "emptyField": "Kan een lege sociale post niet opslaan.",
  "socialPost": "Sociale post",
  "postedFail": "Social Post kon niet worden geplaatst. Probeer het later opnieuw.",
  "mediaFail": "Er is een fout opgetreden tijdens het uploaden van de media. Probeer het later opnieuw.",
  "close": "Sluiten",
  "mediaTooBig": "Sommige bestanden hebben de maximale grootte overschreden en konden niet worden geüpload. De maximale bestandsgrootte is",
  "mentionUsers": "Gebruikers vermelden",
  "mentionUsersFail": "Kan de vermelde gebruikers niet ophalen. Probeer het later opnieuw.",
  "userUnavailable": "Gebruiker niet beschikbaar"
};