import React, { useEffect, useState } from 'react';
import { Paper, Typography, Box, Avatar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBellSlash, faBoxArchive } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import ThreadParticipantList from '../threadParticipantList/threadParticipantList';
import { fetchParticipants } from '../../pages/Chat/chatProvider';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import MuteThread from '../muteThread/muteThread';
import { useArchiveThread, useUnarchiveThread } from '../../hooks/useArchiveThreadHook';
import { GroupDetailsType } from '../../../types/interface/Thread';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';
import { useDispatch } from 'react-redux';
import { useMarkAsMute } from '../../hooks/useMarkAsMute';

export type ThreadInfoProps = {
    description: string;
    createdAt: string;
    title: string;
    avatarUrl?: string | undefined;
    threadType?: string | undefined;
    mutedUntil: string | undefined;
    isArchived: boolean;
    isThreadAdmin: boolean;
    mutedByAdmin: boolean;
    refreshThreadDetail: (isLeaveGroup?: boolean) => void;
    setDisplayThreadInfo: React.Dispatch<React.SetStateAction<boolean>>;
    handleArchiveThread: (threadId: string | undefined) => void;
    setIsGroupSetting: React.Dispatch<React.SetStateAction<boolean>>;
    setGroupEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    handleEditGroupDetail: (groupDetails: GroupDetailsType) => void;
};

export const ThreadInfo = (props: ThreadInfoProps) => {
    const {
        description,
        createdAt,
        title,
        avatarUrl,
        isArchived,
        threadType,
        mutedUntil,
        mutedByAdmin,
        isThreadAdmin,
        setDisplayThreadInfo,
        handleArchiveThread,
        refreshThreadDetail,
        setIsGroupSetting,
        setGroupEditMode,
        handleEditGroupDetail,
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const { id: threadID } = useParams<{ id: string }>();

    const [containerHeight, setContainerHeight] = useState<string>('');
    const [participantSearchString, setParticipantSearchString] = useState<string>('');
    const [anchorEl, setAnchorEl] = useState<(EventTarget & SVGSVGElement) | null>(null);
    const [muteOptionValue, setMuteOptionValue] = useState<string>('60'); // Default value 60 minutes
    const muted = mutedUntil && new Date(mutedUntil) > new Date() ? true : false;
    const { mutate: muteMutation } = useMarkAsMute(refreshThreadDetail);
    const { mutate: archiveMutate } = useArchiveThread(
        () => {
            refreshThreadDetail();
            handleArchiveThread(threadID);
            setDisplayThreadInfo(false);
        },
        () => {
            dispatch(
                addNotification({
                    label: t('chat.archive'),
                    text: t('chat.archiveErrorMsg'),
                    type: 'danger',
                })
            );
        }
    );
    const { mutate: unArchiveMutate } = useUnarchiveThread(
        () => {
            refreshThreadDetail();
            handleArchiveThread(threadID);
            setDisplayThreadInfo(false);
        },
        () => {
            dispatch(
                addNotification({
                    label: t('chat.unarchive'),
                    text: t('chat.unArchiveErrorMsg'),
                    type: 'danger',
                })
            );
        }
    );

    const {
        data: participantsData,
        isLoading: fetchingUserGroups,
        hasNextPage,
        fetchNextPage,
    } = useInfiniteQuery(
        ['participants', threadID, participantSearchString],
        ({ pageParam = 1 }) => fetchParticipants(threadID, pageParam, participantSearchString),
        {
            enabled: threadType === 'groups' || threadType === 'multiple',
            getNextPageParam: (lastPage, allPages) => {
                if (lastPage) {
                    if (lastPage.meta.current_page === lastPage.meta.last_page) return undefined;
                    return allPages.length + 1;
                }
            },
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 10 * 2, // 20 minutes
        }
    );

    const handleSearchValueChange = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setParticipantSearchString(e.target.value);
    });

    useEffect(() => {
        const calculateHeight = () => {
            let totalHeight = 253; // Fix Elements height
            let descriptionHeight = document.getElementById('description')?.offsetHeight; // Description box height
            let threadInfoHeight = document.getElementById('thread-info-avatar')?.offsetHeight; // ThreadInfo avatar box height
            if (descriptionHeight && threadInfoHeight) {
                totalHeight = totalHeight + descriptionHeight + threadInfoHeight;
            }
            if (!isThreadAdmin) {
                let leaveGroupBtnHeight = document.getElementById('leave-group-btn')?.offsetHeight; // Leave group button height
                if (leaveGroupBtnHeight) totalHeight += leaveGroupBtnHeight;
            }
            setContainerHeight(`calc(87vh - ${totalHeight}px)`);
        };
        window.addEventListener('resize', calculateHeight);
        calculateHeight();
        setParticipantSearchString('');
    }, [threadID, description, title, createdAt]);

    const paperStyle =
        participantsData?.pages[0].data.length > 0 ? { height: 'calc(87vh - 85px)' } : { height: 'fit-content', maxHeight: 'calc(87vh - 85px)' };

    return (
        <Paper style={paperStyle} elevation={3} className="thread-info" data-testid={'thread-info'}>
            <Box className="thread-info-title" data-testid="thread-info-title">
                <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>{t('chat.chatInfo')}</Typography>
                <Box>
                    {threadType === 'private' && (
                        <FontAwesomeIcon
                            className="c-pointer"
                            onClick={() => {
                                if (isArchived) {
                                    unArchiveMutate(parseInt(threadID as string));
                                } else {
                                    archiveMutate(parseInt(threadID as string));
                                }
                                queryClient.invalidateQueries(['threads', ['', true]]);
                            }}
                            icon={faBoxArchive}
                            style={{ margin: '0 5px', fontSize: '18px' }}
                        />
                    )}
                    <FontAwesomeIcon
                        className={threadType !== 'public' && !mutedByAdmin ? 'c-pointer' : ''}
                        data-testid="mute-thread-icon"
                        onClick={(e) => {
                            if (threadType !== 'public' && !mutedByAdmin) {
                                if (muted) {
                                    // Unmute API call
                                    muteMutation({
                                        threadID: threadID,
                                        formData: {
                                            muted_until: null,
                                        },
                                    });
                                    navigate(".", {
                                        replace: true,
                                        state: {
                                            ...location?.state,
                                            mutedUntil: ""
                                        }
                                    });
                                } else {
                                    // Open Mute Popover
                                    setAnchorEl(e.currentTarget);
                                }
                            }
                        }}
                        aria-describedby={Boolean(anchorEl) ? 'simple-popover' : undefined}
                        icon={muted || threadType === 'public' || mutedByAdmin ? faBellSlash : faBell}
                        style={{ margin: '0 5px', fontSize: '18px' }}
                    />
                    <MuteThread
                        isOpen={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        muted={muted}
                        mutedUntil={mutedUntil}
                        threadID={threadID}
                        muteOptionValue={muteOptionValue}
                        setAnchorEl={setAnchorEl}
                        setMuteOptionValue={setMuteOptionValue}
                        muteMutation={muteMutation}
                    />
                </Box>
            </Box>
            <Box id="thread-info-avatar" className="thread-info-avatar" data-testid="thread-info-avatar">
                <Avatar sx={{ width: '140px', height: '140px', margin: '0 auto' }} src={avatarUrl} alt={title + 'avtar-img'} />
                <Box sx={{ my: '5px' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '1.5' }}>{title}</Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '1.5' }}>
                        {t('chat.created')} {createdAt}
                    </Typography>
                </Box>
            </Box>
            <Box id="description" className="thread-info-description" data-testid="thread-info-description">
                <div className="d-flex justify-content-between">
                    <Typography sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '1.5' }}>{t('chat.description')}</Typography>
                    {threadType === 'multiple' && isThreadAdmin && (
                        <svg
                            className="edit-group-icon c-pointer"
                            onClick={() => {
                                handleEditGroupDetail({
                                    id: threadID,
                                    title: title,
                                    description: description,
                                    image: avatarUrl,
                                });
                                setIsGroupSetting(true);
                                setGroupEditMode(true);
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            id="Layer_1"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                        >
                            <path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,22c-5.514,0-10-4.486-10-10S6.486,2,12,2s10,4.486,10,10-4.486,10-10,10Zm1.379-15.621l-5.914,5.914c-.944.944-1.465,2.2-1.465,3.535v1.172c0,.553.447,1,1,1h1.172c1.335,0,2.591-.521,3.535-1.465l5.914-5.914c1.17-1.17,1.17-3.072,0-4.242s-3.072-1.17-4.242,0Zm-3.086,8.742c-.559.559-1.332.879-2.121.879h-.172v-.172c0-.789.32-1.562.879-2.121l3.457-3.457,1.414,1.414-3.457,3.457Zm5.914-5.914l-1.043,1.043-1.414-1.414,1.043-1.043c.391-.391,1.023-.391,1.414,0s.39,1.024,0,1.414Z" />
                        </svg>
                    )}
                </div>
                <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '1.5' }}>{description}</Typography>
            </Box>
            {(threadType === 'groups' || threadType === 'multiple') && (
                <ThreadParticipantList
                    fetchingUserGroups={fetchingUserGroups}
                    hasNextPage={hasNextPage}
                    userGroups={participantsData?.pages}
                    isThreadAdmin={isThreadAdmin}
                    threadType={threadType}
                    participantSearchString={participantSearchString}
                    fetchNextPage={fetchNextPage}
                    sx={
                        participantsData?.pages[0].data.length > 0
                            ? { height: containerHeight }
                            : { height: 'fit-content', maxHeight: 'calc(87vh - 85px)' }
                    }
                    handleSearchValueChange={handleSearchValueChange}
                    refreshThreadDetail={refreshThreadDetail}
                    setDisplayThreadInfo={setDisplayThreadInfo}
                />
            )}
        </Paper>
    );
};
