import Client from '../services/axiosService';
import { AxiosResponse, AxiosError } from 'axios';

const socialPostsProvider = {
    likePost(postId: number, isLiked: boolean, success: Function, error?: Function) {
        Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/social/post/like`,
            method: 'post',
            data: {
                id: postId,
                is_liked: isLiked
            }
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    loadPostLikes(postId: number, page: number, success: Function, error?: Function) {
        Client(false).request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/social/post/likes/${postId}${page > 1 ? '?page=' + page : ''}`,
            method: 'get'
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
            if(error){
                error(err);
            }
        });
    },

    loadPostComments(postId: number, success: Function, error?: Function) {
        Client(false).request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/social/post/comments/${postId}`,
            method: 'get'
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    leaveComment(postId: number, value: string, parentCommentId: number | null, files: Array<File>, success: Function, error?: Function) {
        Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/social/post/comment/${postId}`,
            method: 'post',
            data: {
                id: postId,
                value: value,
                comment_id: parentCommentId,
                files: files
            }
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    deletePost(postId: number, success: Function, error?: Function) {
        Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/social/post/${postId}`,
            method: 'delete'
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },
    
    deleteComment(postId: number, commentId: number, success: Function, error?: Function) {
        Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/social/post/${postId}/comment/${commentId}`,
            method: 'delete'
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    unfollowUser(userId: number, success: Function, error?: Function) {
        Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/json/social/users/${userId}`,
            method: 'delete'
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    reportPost(postId: number, message: string, success: Function, error?: Function) {
        Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/social/post/report`,
            method: 'post',
            data: {
                postId: postId,
                reason: message
            }
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    saveSocialPost(data: any, success: Function, error?: Function) {
        Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/social/post/store`,
            method: 'post',
            data: data
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
            if(error){
                error(err);
            }
        });
    },

    saveSocialPostFile(data: any, success: Function, error?: Function) {
        Client(true, false).request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/social/post/image`,
            method: 'post',
            data: data,
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
            if(error){
                error(err);
            }
        });
    }
}

export default socialPostsProvider;