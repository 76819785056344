import React from 'react';
import { Box, Typography, SxProps, TypographyProps } from '@mui/material';

export type IconTextProps = {
    icon: string | React.ReactNode;
    text: string;
    IconTextContainerSx?: SxProps;
    textProps?: TypographyProps;
    iconStyle?: React.CSSProperties;
    textStyle?: React.CSSProperties;
};

export const IconText = (props: IconTextProps) => {
    const { icon, text, iconStyle, textProps, IconTextContainerSx } = props;
    return (
        <Box
            sx={{
                display: 'flex',
                gap: '10px',
                ...IconTextContainerSx,
            }}
            data-testid="icon-text"
        >
            {typeof icon === 'string' ? (
                <span
                    className={icon}
                    style={{
                        lineHeight: '1.5',
                        fontSize: '16px',
                        width: '16px',
                        textAlign: 'center',
                        fontWeight: 400,
                        ...iconStyle,
                    }}
                    data-testid="icon-text-icon"
                />
            ) : (
                icon
            )}
            <Typography
                {...textProps}
                sx={{
                    fontSize: '16px',
                    lineHeight: '1.5',
                    fontWeight: 400,
                    ...textProps?.sx,
                }}
                data-testid="icon-text-text"
            >
                {text}
            </Typography>
        </Box>
    );
};
