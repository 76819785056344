export default {
  "please enter your company reference": "Por favor, introduzca la referencia de su empresa",
  "enter your company reference": "Introduzca la referencia de su empresa",
  "please enter your user ID and password": "Por favor introduzca su usuario y contraseña",
  "please enter user id": "Por favor introduzca su identificación de usuario",
  "enter password": "Por favor, introduzca su contraseña",
  "forgot password": "Olvidó su contraseña",
  "errors": {
    "domain name can't be empty": "El nombre de dominio no puede estar vacío",
    "user name can't be empty": "El nombre de usuario no puede estar vacío",
    "password can't be empty": "La contraseña no puede estar vacía",
    "incorrect user id or password": "Identificación de usuario o contraseña incorrecta",
    "domain does not exist": "El dominio '{{domain}}' no existe",
    "account locked": "Se han producido demasiados intentos, la cuenta ha sido bloqueada"
  },
  "hello": "Hola",
  "log in": "Iniciar sesión",
  "new user": "Nuevo usuario",
  "create new account": "Crear una cuenta nueva",
  "next": "Siguiente",
  "verify": "VERIFICAR",
  "contact support": "Contacto de soporte técnico",
  "back": "ATRÁS"
};
