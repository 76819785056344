import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { logout } from '../../../../shared/reducers/user/actionTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faCircle, faRightFromBracket } from '@fortawesome/pro-solid-svg-icons';
import { faAngleDown, faBars, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import authService from '../../../services/authService';
import NotificationsComponent from '../../notifications/notificationsList';
import '../rightNavComponent/rightNavStyles.scss';
import CookieModal from '../../../../web/components/Settings/cookieModal';
import { IconCard } from '../../IconCard/iconCard';
import { RightNavSidePanel } from '../../rightNavSidePanel/rightNavSidePanel';
import { FormControlLabel, Checkbox, Box, Typography } from '@mui/material';
import { useQuery, useMutation } from 'react-query';
import Client from '../../../services/axiosService';
import { SideNavItem } from '../../../../types/types';

export type RightNavComponentProps = {
    iconClass?: string;
    menuClass?: string;
    colorForeground: string;
    colorBackground: string;
    isMobileView: boolean;
    menuItems: SideNavItem[];
    unreadThreads: number;
};

const fetchP2PNotificationSettings = async () => {
    return await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/preferences/p2p-email`,
        method: 'get',
    });
};

const updateP2PNotificationSettings = async (enabled: boolean) => {
    return await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/preferences/p2p-email`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            enabled: enabled,
        },
    });
};

const RightNavComponent: FunctionComponent<RightNavComponentProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isMobileView, menuItems, unreadThreads } = props;

    const [isOpened, setOpened] = useState(false);
    const userInfo = useSelector((state: any) => state.app.user);
    const tenantInfo = useSelector((state: any) => state.app.tenant);
    const tyBranding = useSelector((state: any) => state.app.tenant.tyBranding);
    // "notificationsOpened" determines if we should draw the menu items, to scroll the Notifications
    // window into view the "notificationsContainerWidth" needs to be switched
    const [notificationsCount, setNotifications] = useState<number>(0);
    const [notificationsOpened, openNotifications] = useState<boolean>(false);
    const [settingsOpened, setSettingsOpened] = useState<boolean>(false);
    const [openCookiesModal, setOpenCookiesModal] = useState<boolean>(false);
    const [reloadData, setReloadData] = useState<boolean>(true);
    const [performanceCookie, setPerformanceCookie] = useState<boolean>(userInfo.recordPerformance);
    const [analyticsCookie, setAnalyticsCookie] = useState<boolean>(userInfo.recordAnalytics);
    const [openedEmailSettings, setOpenedEmailSettings] = useState<boolean>(false);
    const [openedNotificationSettings, setOpenedNotificationSettings] = useState<boolean>(false);
    const [notificationsContainerWidth, setNotificationsWidth] = useState('-100%');
    const [p2pEmailPreference, setP2PEmailPreference] = useState<boolean | null>(null);
    const {
        isLoading: isLoadingEmailPreference,
        data: p2pPreference,
        refetch: p2pReferenceFetch,
    } = useQuery(['p2pReference'], () => fetchP2PNotificationSettings(), {
        enabled: false,
    });
    const { mutate: updateP2PNotification } = useMutation(updateP2PNotificationSettings);

    const showNotifications = () => {
        openNotifications(true);
        toggleNotificationsWidth();
    };

    const toggleNotificationsWidth = () => {
        const newWidth = notificationsContainerWidth === '0' ? '-100%' : '0';
        setNotificationsWidth(newWidth);
    };

    const toggleMenu = () => {
        setSettingsOpened(false);
        if (isOpened && notificationsOpened) {
            toggleNotificationsWidth();
        }
        setOpenedNotificationSettings(false);
        setOpened(!isOpened);
    };

    const dispatch = useDispatch();

    const logoutUser = () => {
        localStorage.setItem('is_sso_user', 'false');
        authService.logout();
        dispatch(logout());
        window.location.href = `/${tenantInfo.ref}/logout`;
    };

    const renderMenuIcon = (icon: IconDefinition | string, style?: any) => {
        return (
            <div
                className={'thumb48 rounded-circle d-flex justify-content-center align-items-center'}
                style={style ? { ...style } : { background: 'white', border: '1px solid #dee2e6', borderWidth: '1px' }}
            >
                {typeof icon === 'string' ? (
                    <span className={`${icon} font-24`} style={{ color: '#15385B', fontStyle: 'none' }} />
                ) : (
                    <FontAwesomeIcon icon={icon} style={{ color: '#15385B' }} className={'font-24 fa-solid'} />
                )}
            </div>
        );
    };

    const transitionHandler = (e: any) => {
        const right = e.target.style.right;
        if (right === '0px') openNotifications(true);
        else if (right === '-100%') openNotifications(false);
    };

    let menuStyle = {
        transition: '1s',
        color: props.colorForeground,
        backgroundColor: props.colorBackground,
        zIndex: 2,
    };

    const goBack = () => {
        setSettingsOpened(false);
    };

    const showCookiesModal = () => {
        if (reloadData) {
            setAnalyticsCookie(userInfo.recordAnalytics);
            setPerformanceCookie(userInfo.recordPerformance);
        }

        setOpenCookiesModal(true);
    };

    const closeModalCallback = (data: any) => {
        setReloadData(false);
        setAnalyticsCookie(data.recordAnalytics);
        setPerformanceCookie(data.recordPerformance);
        setOpenCookiesModal(false);
    };

    const handleEmailPrefereceClick = () => {
        setOpenedEmailSettings(!openedEmailSettings);
    };

    useEffect(() => {
        if (openedEmailSettings && !p2pEmailPreference) {
            p2pReferenceFetch();
        }
    }, [openedEmailSettings]);

    const showChangePasswordOption = () => {
        const isLoginViaSSO = localStorage.getItem('is_sso_user');
        return isLoginViaSSO ? isLoginViaSSO === 'false' : false;
    };

    return (
        <React.Fragment>
            <div data-testid="rightnav-component" onClick={toggleMenu} className={classNames('c-pointer', props.iconClass)}>
                <FontAwesomeIcon icon={faBars} />
                {notificationsCount > 0 && (
                    <FontAwesomeIcon icon={faCircle} className={'font-14 text-danger position-relative'} style={{ top: '-5px', left: '-6px' }} />
                )}
            </div>
            <div
                id={'page-frame-right-wrapper'}
                data-testid="rightnav-component-wrapper"
                className={classNames('vh-100 stick-top position-absolute ', {
                    'w-none': !isOpened,
                    shadow: isOpened,
                    'stick-right': !isMobileView,
                    'stick-left': isMobileView,
                })}
                style={menuStyle}
            >
                <div id={'page-frame-right'} className={classNames('vh-100 d-flex flex-column', props.menuClass)} style={menuStyle}>
                    {tenantInfo.allowCookies && settingsOpened ? (
                        <h1 className={classNames('ms-3 mb-2 mt-4 font-weight-600 font-24 c-pointer')} onClick={goBack}>
                            <FontAwesomeIcon className={'me-3'} icon={faAngleLeft} style={{ marginLeft: '30px' }} />
                            <span>{t('partials.menus.settings')}</span>
                        </h1>
                    ) : (
                        <IconCard
                            handleClick={() => {}}
                            icon={userInfo.image_url}
                            sx={{
                                backgroundColor: props.colorBackground,
                                color: props.colorForeground,
                                padding: '25px 30px',
                            }}
                            className="profile-description"
                            iconClassName="rounded-circle thumb48 float-left me-3"
                            titleStyle={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                        >
                            <Box className="c-pointer" onClick={() => navigate('/connect')}>
                                <h3 className={'font-weight-bold mb-0 pt-2'}>{userInfo.full_name}</h3>
                                <p>{userInfo.jobTitle}</p>
                            </Box>
                            <Box>
                                <em className="fal fa-times c-pointer float-right font-24 pt-2" onClick={toggleMenu} />
                            </Box>
                        </IconCard>
                    )}
                    {!notificationsOpened && !settingsOpened && !openedNotificationSettings && (
                        <>
                            <div
                                className="font-16 flex-grow"
                                style={{ padding: '0 30px', maxHeight: isMobileView ? '65vh' : 'auto', overflowY: isMobileView ? 'scroll' : 'auto' }}
                            >
                                {isMobileView && (
                                    <>
                                        {menuItems &&
                                            menuItems.map((item) => (
                                                <IconCard
                                                    key={item.path}
                                                    title={item.title}
                                                    icon={renderMenuIcon(item.icon_class)}
                                                    iconBadge={item.route_url === '/messages' ? true : false}
                                                    iconBadgeCount={unreadThreads}
                                                    iconBadgeProps={{
                                                        badgeContent: unreadThreads,
                                                        color: 'error',
                                                        sx: {
                                                            '& .MuiBadge-badge': {
                                                                right: '5px',
                                                                top: '2px',
                                                            },
                                                        },
                                                    }}
                                                    titleStyle={{ marginLeft: '1rem' }}
                                                    handleClick={() => {
                                                        navigate(item.route_url === '/messages' ? '/chat' : item.route_url), toggleMenu();
                                                    }}
                                                    sx={{ color: props.colorForeground }}
                                                />
                                            ))}
                                    </>
                                )}
                                <IconCard
                                    title={t('partials.menus.profile')}
                                    titleStyle={{ marginLeft: '1rem' }}
                                    icon={renderMenuIcon(isMobileView ? 'fa-regular fa-user' : 'fa-solid fa-user')}
                                    handleClick={() => {
                                        navigate(`/connect/people/${userInfo.connect_id}`), toggleMenu();
                                    }}
                                    sx={{ color: props.colorForeground }}
                                />
                                <IconCard
                                    title={t('partials.menus.notifications')}
                                    titleStyle={{ marginLeft: '1rem' }}
                                    icon={renderMenuIcon(isMobileView ? 'fa-regular fa-bell' : 'fa-solid fa-bell')}
                                    handleClick={showNotifications}
                                    sx={{ color: props.colorForeground }}
                                >
                                    {notificationsCount > 0 && (
                                        <span className={'ms-2 py-1 px-2 font-12 line-height-1 rounded-circle text-white bg-badge-secondary'}>
                                            {notificationsCount}
                                        </span>
                                    )}
                                </IconCard>
                                {showChangePasswordOption() && (
                                    <IconCard
                                        title={t('partials.menus.change password')}
                                        titleStyle={{ marginLeft: '1rem' }}
                                        icon={renderMenuIcon(isMobileView ? 'fa-regular fa-lock' : 'fa-solid fa-lock')}
                                        handleClick={() => {
                                            (window.location.href = '/password/change'), toggleMenu();
                                        }}
                                        sx={{ color: props.colorForeground }}
                                    />
                                )}
                                <IconCard
                                    title={t('partials.menus.settings')}
                                    titleStyle={{ marginLeft: '1rem' }}
                                    icon={renderMenuIcon(isMobileView ? 'fa-regular fa-gear' : 'fa-solid fa-gear')}
                                    handleClick={() => setSettingsOpened(true)}
                                    sx={{ color: props.colorForeground }}
                                />
                            </div>
                            <IconCard
                                title={t('partials.menus.signOut')}
                                icon={renderMenuIcon(faRightFromBracket)}
                                handleClick={logoutUser}
                                className="sign-out c-pointer"
                                titleClassName="menu-text"
                                sx={{ color: props.colorForeground }}
                                titleStyle={{ marginTop: '10px', marginLeft: '1rem' }}
                            />
                        </>
                    )}
                    {notificationsOpened ? (
                        <div
                            style={{
                                transition: '0.5s right',
                                right: notificationsContainerWidth,
                                height: notificationsOpened ? '100%' : '0',
                            }}
                            className={classNames('position-relative')}
                            onTransitionEnd={transitionHandler}
                        >
                            <NotificationsComponent
                                containerClassName={classNames('h-100 w-100')}
                                onBackBtn={toggleNotificationsWidth}
                                setNotificationCount={setNotifications}
                            />
                        </div>
                    ) : null}
                    {settingsOpened && !openedNotificationSettings && (
                        <RightNavSidePanel onBack={() => setSettingsOpened(false)} title={t('partials.menus.settings')}>
                            {tenantInfo.allowCookies && (
                                <IconCard
                                    icon={renderMenuIcon('fa-solid fa-eye', {
                                        border: '1px solid #15385B',
                                        borderWidth: '2px',
                                    })}
                                    handleClick={showCookiesModal}
                                    title={t('partials.settingOptions.title')}
                                    titleStyle={{ marginLeft: '1rem' }}
                                />
                            )}
                            <IconCard
                                icon={renderMenuIcon('fa-solid fa-bell', {
                                    border: '1px solid #15385B',
                                    borderWidth: '2px',
                                })}
                                handleClick={() => setOpenedNotificationSettings(true)}
                                title={t('partials.settingOptions.notificationSettings')}
                                titleStyle={{ marginLeft: '1rem' }}
                            />
                        </RightNavSidePanel>
                    )}
                    {openedNotificationSettings && (
                        <RightNavSidePanel
                            onBack={() => {
                                setOpenedNotificationSettings(false);
                                setOpenedEmailSettings(false);
                            }}
                            title={t('partials.settingOptions.notificationSettings')}
                        >
                            <IconCard
                                icon={renderMenuIcon('fa-solid fa-envelope-open-text', {
                                    border: '1px solid #15385B',
                                    borderWidth: '2px',
                                })}
                                title={t('partials.settingOptions.emails')}
                                titleStyle={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    marginLeft: '1rem',
                                }}
                                handleClick={handleEmailPrefereceClick}
                            >
                                <FontAwesomeIcon
                                    icon={!openedEmailSettings ? faAngleDown : faAngleUp}
                                    className={'font-24 fa-solid'}
                                    style={{ color: '#15385B' }}
                                />
                            </IconCard>
                            {openedEmailSettings && (
                                <div className={'ms-4'} style={{ marginTop: '10px' }}>
                                    {!isLoadingEmailPreference && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        borderRadius: '8px',
                                                        color: '#15385B',
                                                        '&.Mui-checked': {
                                                            color: '#15385B',
                                                            stroke: '#fff',
                                                            strokeWidth: '1px',
                                                        },
                                                        '& .MuiSvgIcon-root': { fontSize: 28 },
                                                    }}
                                                    inputProps={{ 'aria-label': 'email p2p checkbox' }}
                                                    onChange={(e) => {
                                                        setP2PEmailPreference(e.target.checked);
                                                        updateP2PNotification(e.target.checked);
                                                    }}
                                                    checked={p2pEmailPreference != null ? p2pEmailPreference : p2pPreference?.data?.enabled}
                                                />
                                            }
                                            sx={{ color: '#15385B', fontSize: '14px' }}
                                            label={t('partials.settingOptions.p2pEmailsLabel', { p2pLabel: tyBranding.tycText.menu.value })}
                                        />
                                    )}
                                </div>
                            )}
                        </RightNavSidePanel>
                    )}
                </div>
            </div>
            {openCookiesModal ? (
                <CookieModal
                    isOpen={openCookiesModal}
                    recordPerformance={performanceCookie}
                    recordAnalytics={analyticsCookie}
                    cookieTermsLink={userInfo.cookieTermsLink}
                    onSaveAction={closeModalCallback}
                />
            ) : null}
        </React.Fragment>
    );
};

export default RightNavComponent;
