export default {
    "sharePost": "Start sharing...",
    "what do you have to say": "What do you have to say",
    "emptyField": "Can't save an empty social post.",
    "socialPost": "Social Post ",
    "postedFail": "Social Post couldn't be posted, please try again later.",
    "mediaFail": "There was an error during the media upload, please try again later.",
    "close": "Close",
    "mediaTooBig": "Some files exceeded the maximum size and couldn't be uploaded. Maximum file size is ",
    "mentionUsers": "Mention Users",
    "mentionUsersFail": "Unable to fetch mention users, please try again later.",
    "userUnavailable": "User unavailable"
};