export default {
  "locations": "Localisations",
  "contacts": "Contacts",
  "favourites": "Favoris",
  "all": "Tous",
  "noDataFound": "Aucune donnée n'a été trouvée",
  "thereAreNoPost": "Il n'y a pas de publications sur les réseaux sociaux, commencez à publier pour les voir ici !",
  "noOrgChart": "Aucun organigramme n'est associé à cet utilisateur.",
  "awarded": "Décerné",
  "expires": "Expire",
  "contactDetails": "Informations de contact",
  "followers": "Abonnés",
  "following": "Suivant",
  "follow": "Suivez",
  "unfollow": "Ne plus suivre",
  "unsnooze": "Débloquez",
  "snooze": "Snooze",
  "1Hour": "1 heure",
  "8Hours": "8 heures",
  "24Hours": "24 heures",
  "1Week": "1 semaine",
  "1Month": "1 mois",
  "1Year": "1 an",
  "message": "Message",
  "details": "Détails",
  "posts": "Postes",
  "organisation": "Organisation",
  "profileImageHeader": "Image de profil",
  "headerImageHeader": "Image d'en-tête de profil",
  "uploadFile": "Télécharger un fichier",
  "zoom": "Agrandissement (%)",
  "rotate": "Faire pivoter",
  "done": "Terminé",
  "ratio": "Conserver le ratio d'aspect",
  "on": "Activer",
  "off": "Désactiver",
  "search": "Rechercher",
  "noFavourites": "Commencez à ajouter des contacts favoris !",
  "manager": "Directeur",
  "location": "Lieu",
  "primaryEmail": "E-mail principal",
  "dob": "Date de naissance",
  "start": "Date de début",
  "bio": "Biographie",
  "chart": "Organigramme",
  "close": "Fermer",
  "address": "Adresse",
  "description": "Descriptif",
  "fName": "Prénom",
  "lName": "Nom de famille",
  "title": "Titre",
  "department": "Département",
  "save": "Enregistrer",
  "error": "Une erreur s'est produite",
  "errorLong": "veuillez réessayer plus tard.",
  "success": "Succès !",
  "successLong": "Détails enregistrés correctement",
  "email": "Courrier électronique",
  "badge": "Badges",
  "skills": "Compétences",
  "phone": "Téléphone",
  "hobbies": "Loisirs",
  "clearAll": "Tout effacer",
  "quickFilters": "Filtres rapides",
  "customSearchTextPlaceholder": "Commencez à taper et appuyez sur Entrée...",
  "working hours": "Heures de travail",
  "fun facts": "Faits amusants",
  "noResultsFound": "Aucun résultat n'a été trouvé",
  "charsLimitMsg": "Veuillez saisir 3 caractères ou plus pour effectuer la recherche",
  "errorLoadingFollowers": "Erreur lors du chargement des abonnés",
  "errorLoadingFollowings": "Erreur lors du chargement des éléments suivants",
  "followsYou": "Vous suit",
  "searchContacts": "Rechercher des contacts",
  "searchLocations": "Localisations de recherche",
  "allContacts": "Tous les contacts",
  "edit": "Modifier",
  "people": "Gens",
  "directory": "Répertoire",
  "noSearchResultsContactDescription": "Oups ! Il semblerait que nous n'ayons trouvé aucun contact correspondant à votre recherche.",
  "noContactResultsDescription": "Oups ! Il semblerait que nous n'ayons trouvé aucun contact.",
  "noSearchResultsFavouriteDescription": "Oups ! Il semblerait que nous n'ayons trouvé aucun contact favori correspondant à votre recherche.",
  "noFavouriteResultsDescription": "Oups ! Aucun contact favori n'a été trouvé. Ajoutez des contacts à vos favoris pour les consulter ici.",
  "noSearchResultsLocationDescription": "Oups ! Il semblerait que nous n'ayons trouvé aucun lieu correspondant à votre recherche.",
  "noLocationResultsDescription": "Oups ! Il semblerait que nous n'ayons pas pu trouver de localisation.",
  "clearFilters": "Effacer les filtres",
  "filters": "Filtres"
};