import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    EditorState,
    convertToRaw,
    convertFromRaw,
    getDefaultKeyBinding,
    RichUtils,
    Modifier,
    RawDraftContentState,
    RawDraftContentBlock,
    CompositeDecorator,
    ContentBlock,
    ContentState,
    CharacterMetadata,
} from 'draft-js';
import Editor, { EditorPlugin } from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import createLinkDetectionPlugin from 'draft-js-link-detection-plugin';
import { MentionSuggestionsPubProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/MentionSuggestions';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { MentionData } from '@draft-js-plugins/mention';
import { useTranslation } from 'react-i18next';
import { stateToHTML } from 'draft-js-export-html';
import '@draft-js-plugins/mention/lib/plugin.css';

import mentionsStyles from './MentionsStyles.module.css';
import usersService from '../../../services/usersService';
import { addNotification } from '../../../../shared/reducers/notifications/actionTypes';
import './Draft.min.css';
import './DraftEditor.scss';
import { LinkPreview } from '../../linkPreview/linkPreview';
import { useLinkPreview } from '../../../hooks/useLinkPreviewHook';
import { useDebounce } from '../../../hooks/useDebounceHook';
import { FilePreviewList } from '../../filePreviewList/filePreviewList';
import { getFirstUrl } from '../../../utils/urlFormatter';

export type DraftEditorProps = {
    style?: any;
    className?: string;
    defaultValue?: string;
    placeholder?: string;
    allowSubmitOnEnter?: boolean; // If true, allow submit on enter key press
    isSubmitContent?: boolean; // If true, save/submit input value
    isBoldText?: boolean;
    isItalicText?: boolean;
    editorFocus?: boolean;
    readOnly?: boolean;
    handleOnChange?: Function;
    handleKeyPress?: Function;
    deletedUserList?: Array<number>;
    linkPreview?: boolean;
    resetEditorTextValue?: boolean; // if true, reset draft editor state
    filePreview?: boolean;
    filePreviewFiles?: File[];
    selectedEmoji?: any;
    sendHTMLContent?: boolean;
    setSelectedEmoji?: React.Dispatch<any>;
    setEditorFocus?: React.Dispatch<React.SetStateAction<boolean>>;
    handleRemoveFile?: (e: React.MouseEvent<HTMLButtonElement>, index: number) => void;
    handleEditorContentSubmit?: (value: string) => void;
    setEditorTextValue?: React.Dispatch<React.SetStateAction<string>>;
    setIsBoldText?: React.Dispatch<React.SetStateAction<boolean>>;
    setIsItalicText?: React.Dispatch<React.SetStateAction<boolean>>;
    setIsResetEditorTextValue?: React.Dispatch<React.SetStateAction<boolean>>;
    setLinkPreviewDisplay?: React.Dispatch<React.SetStateAction<any>>;
};

type LinkProps = {
    contentState: ContentState;
    entityKey: string;
    children?: ReactNode;
};

const DraftEditor = ({
    style,
    className,
    readOnly,
    isBoldText,
    isItalicText,
    isSubmitContent,
    editorFocus,
    allowSubmitOnEnter,
    defaultValue,
    placeholder,
    handleOnChange,
    handleKeyPress,
    deletedUserList,
    linkPreview,
    resetEditorTextValue,
    filePreview,
    filePreviewFiles,
    selectedEmoji,
    sendHTMLContent = true,
    setSelectedEmoji,
    setEditorFocus,
    setEditorTextValue,
    handleEditorContentSubmit,
    setIsBoldText,
    setIsItalicText,
    setIsResetEditorTextValue,
    setLinkPreviewDisplay,
    handleRemoveFile,
}: DraftEditorProps) => {
    const linkType = 'LINK';
    const [editorContent, setEditorContent] = useState(defaultValue ? defaultValue : '');
    const ref = useRef<Editor>(null);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [open, setOpen] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<MentionData[]>([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { debouncedValue: linkPreviewUrl } = useDebounce(linkPreview ? getFirstUrl(editorState.getCurrentContent().getPlainText()) : '');
    const { data: linkPreviewData, error: linkPreviewError, isLoading: fetchingLinkPreview } = useLinkPreview(linkPreview ? linkPreviewUrl : '');

    useEffect(() => {
        if (linkPreview && linkPreviewUrl && !fetchingLinkPreview && setLinkPreviewDisplay) {
            setLinkPreviewDisplay(true);
        } else {
            if (setLinkPreviewDisplay) setLinkPreviewDisplay(false);
        }
    }, [linkPreviewUrl, fetchingLinkPreview]);

    useEffect(() => {
        if (isItalicText && setIsItalicText) {
            onChange(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
            setIsItalicText(false);
        }

        if (isBoldText && setIsBoldText) {
            onChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
            setIsBoldText(false);
        }

        if (isSubmitContent) onSubmit();
        if (defaultValue && defaultValue.length > 0) renderEditorState();

        if (resetEditorTextValue) {
            setEditorState(EditorState.createEmpty());
            if (setIsResetEditorTextValue) setIsResetEditorTextValue(false);
        }

        if (editorFocus) {
            ref.current!.focus();
            if (setEditorFocus) setEditorFocus(false);
        }

        if (selectedEmoji && selectedEmoji.emoji.length > 0) {
            appendEmojiToEditor(selectedEmoji.emoji);
            if (setSelectedEmoji) setSelectedEmoji(null);
        }
    }, [isItalicText, isBoldText, isSubmitContent, defaultValue?.length, resetEditorTextValue, selectedEmoji, selectedEmoji?.unified, editorFocus]);

    const MentionUserListItem = (props: any): ReactElement => {
        const { mention, theme, searchValue, isFocused, ...parentProps } = props;

        return (
            <div data-testid='mention-user' {...parentProps}>
                <div className={theme?.mentionSuggestionsEntryContainer}>
                    <div className={theme?.mentionSuggestionsEntryContainerLeft}>
                        <img src={mention.avatar} className={theme?.mentionSuggestionsEntryAvatar} role='presentation' />
                    </div>

                    <div className={theme?.mentionSuggestionsEntryContainerRight}>
                        <div className={theme?.mentionSuggestionsEntryText}>{mention.name}</div>

                        <div className={theme?.mentionSuggestionsEntryTitle}>{mention.title}</div>
                    </div>
                </div>
            </div>
        );
    };

    const isUserUnavailable = (id: number) => {
        if (!deletedUserList?.length) return false;
        return deletedUserList.findIndex((userId) => userId === id) > -1;
    };

    const fetchUnavailableUser = (raw: RawDraftContentState) => {
        for (let key in raw.entityMap) {
            const ent = raw.entityMap[key];
            if (ent.type === 'mention' && isUserUnavailable(ent.data.mention.id)) {
                ent.data.mention.name = '' + t('socialPost.userUnavailable');
                ent.data.mention.link = -1;
            }
        }
        return raw;
    };

    const appendEmojiToEditor = (emoji: string) => {
        const currentContentState = editorState.getCurrentContent();
        const appendedContentState = Modifier.insertText(currentContentState, editorState.getSelection(), emoji);
        const newEditorState = EditorState.push(editorState, appendedContentState, 'insert-characters');
        setEditorState(newEditorState);
    };

    const findLinkEntities = (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
        contentBlock.findEntityRanges((character: CharacterMetadata) => {
            const entityKey = character.getEntity();
            return entityKey !== null && contentState.getEntity(entityKey).getType() === linkType;
        }, callback);
    };

    const Link: React.FC<LinkProps> = (props) => {
        const { url } = props.contentState.getEntity(props.entityKey).getData();
        return (
            <a href={url} target='_blank' title={url}>
                {props.children}
            </a>
        );
    };

    const { MentionSuggestions, plugins }: { MentionSuggestions: React.ComponentType<MentionSuggestionsPubProps>; plugins: EditorPlugin[] } =
        useMemo(() => {
            const mentionPlugin = createMentionPlugin({
                entityMutability: 'IMMUTABLE',
                theme: mentionsStyles,
                mentionPrefix: '@',
                supportWhitespace: true,
                mentionComponent: (mentionProps: any) => {
                    return (
                        <a
                            onClick={() => {
                                if (mentionProps.mention.link != -1) navigate(`/connect/people/${mentionProps.mention.link}`);
                            }}
                            className={`${mentionProps.className} ${mentionProps.mention.link != -1 ? 'mention-link' : ''}`}
                            spellCheck='false'
                            data-testid='mentionLink'
                        >
                            <span data-offset-key={`${mentionProps.children[0].key}`}>
                                <span data-text='true'>@{`${mentionProps.mention.name}`}</span>
                            </span>
                        </a>
                    );
                },
            } as any);
            // eslint-disable-next-line no-shadow
            const { MentionSuggestions } = mentionPlugin;
            const linkDetectionPlugin = createLinkDetectionPlugin();
            const linkDecoratorPlugin = {
                decorators: [
                    {
                        strategy: findLinkEntities,
                        component: Link,
                    },
                ],
            };
            // eslint-disable-next-line no-shadow
            const plugins = [mentionPlugin, linkDecoratorPlugin, linkDetectionPlugin] as EditorPlugin[];
            return { plugins, MentionSuggestions };
        }, []);

    const getMentionedUsers = (newEditorState: EditorState = editorState) => {
        const raw = convertToRaw(newEditorState.getCurrentContent());
        let mentionedUsers = [];
        for (let key in raw.entityMap) {
            const ent = raw.entityMap[key];
            if (ent.type === 'mention') {
                mentionedUsers.push(ent.data.mention); // Extract Mentioned Users From Content
            }
        }

        return mentionedUsers;
    };

    const onChange = useCallback((editorState: EditorState) => {
        if (handleOnChange) {
            handleOnChange(); // Parent onChange Function Call
        }
        if (!editorState.getSelection().getHasFocus() && !sendHTMLContent) {
            editorState = EditorState.moveFocusToEnd(editorState);
        }
        let mentionedUsers = getMentionedUsers(editorState);
        let value = '';
        if (mentionedUsers.length > 0) {
            value = getFormattedEditorTextValue(mentionedUsers, stateToHTML(editorState.getCurrentContent()));
        } else {
            value = stateToHTML(editorState.getCurrentContent());
        }

        if (setEditorTextValue) {
            setEditorTextValue(value === '<p><br></p>' ? '' : value.trim());
        }

        setEditorState(editorState);
    }, []);

    const onOpenChange = useCallback((open: boolean) => {
        setOpen(open);
    }, []);

    const onSearchChange = useCallback(async ({ value }: { value: string }) => {
        // API call after typing 3 characters prefix with @
        if (value.length > 1) {
            await usersService.getMentionUsers(
                value,
                (resp: AxiosResponse) => {
                    setSuggestions(resp.data.data);
                },
                () => {
                    dispatch(
                        addNotification({
                            label: t('socialPost.mentionUsers'),
                            text: t('socialPost.mentionUsersFail'),
                            type: 'danger',
                        })
                    );
                }
            );
        } else {
            setSuggestions([]);
        }
    }, []);

    const renderEditorState = () => {
        // Insert Normal Text Into Editor
        const insertText = (text: string, editorState: EditorState) => {
            const currentContent = editorState.getCurrentContent(),
                currentSelection = editorState.getSelection();

            const newContent = Modifier.replaceText(currentContent, currentSelection, text);

            const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
            return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
        };
        try {
            // Render Editor Content
            const parsedEditorContent = JSON.parse(editorContent);
            const editorContentValue = fetchUnavailableUser(JSON.parse(parsedEditorContent.contentValue));
            setEditorState(EditorState.createWithContent(convertFromRaw(editorContentValue)));
        } catch (error) {
            // Render Normal Text
            setEditorState(insertText(editorContent, editorState));
        }
    };

    const getFormattedEditorTextValue = (mentionedUsers: MentionData[], value: string) => {
        var editorTextValue = '';
        // Add User Id in Normal text
        for (var i = 0; i < mentionedUsers.length; i++) {
            if (value.includes(`@${mentionedUsers[i].name}`)) {
                if (i == 0) {
                    editorTextValue = value.replaceAll(`@${mentionedUsers[i].name}`, `![[id${mentionedUsers[i].id}]]`);
                    continue;
                }
                editorTextValue = editorTextValue.replaceAll(`@${mentionedUsers[i].name}`, `![[id${mentionedUsers[i].id}]]`);
            }
        }
        return editorTextValue;
    };

    const onSubmit = () => {
        if (!handleEditorContentSubmit) {
            return;
        }

        let mentionedUsers = getMentionedUsers();
        let editorTextValue = '';
        let draftEditorContent = sendHTMLContent ? stateToHTML(editorState.getCurrentContent()) : editorState.getCurrentContent().getPlainText();
        if (mentionedUsers.length > 0) {
            editorTextValue = getFormattedEditorTextValue(mentionedUsers, draftEditorContent);
        } else {
            editorTextValue = draftEditorContent;
        }

        handleEditorContentSubmit(editorTextValue.trim());
        setEditorContent('');
        setEditorState(EditorState.createEmpty());
        if (allowSubmitOnEnter) {
            setTimeout(() => {
                ref.current!.focus();
            }, 0);
        }
    };

    const keyBindingFn = (event: any) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Tab') {
            // Select Mention User By Up and Down Arrows and Tab
            return undefined;
        }

        if (handleKeyPress) {
            handleKeyPress(); // Parent Key Press Function Call
        }

        // Enter Submit
        if (event.keyCode === 13 && allowSubmitOnEnter && !event.nativeEvent.shiftKey) {
            ref.current!.blur();
            onSubmit();
            return 'Enter';
        }

        return getDefaultKeyBinding(event);
    };

    const onAddMention = () => {
        setSuggestions([]); // Empty Suggestions Once mention has been selected
    };

    return (
        <div
            data-testid='draft-editor'
            className={`editor ${className} ${readOnly ? 'read-only' : ''}`}
            onClick={() => {
                ref.current!.focus();
            }}
            style={style}
        >
            {linkPreview && linkPreviewUrl && !fetchingLinkPreview && (filePreviewFiles ? filePreviewFiles.length < 1 : false) && (
                <LinkPreview
                    title={linkPreviewData?.title}
                    url={linkPreviewData?.url ? linkPreviewData?.url : linkPreviewUrl}
                    description={linkPreviewData?.description}
                    image={linkPreviewData?.image}
                    imageDirection='left'
                    imageProps={{
                        xs: 3,
                        sx: {
                            backgroundColor: 'rgb(245, 245, 245)',
                            borderTopLeftRadius: '10px',
                            borderBottomLeftRadius: '10px',
                            backgroundImage: linkPreviewData?.image ? `url(${linkPreviewData?.image})` : 'none',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        },
                    }}
                    descriptionProps={{
                        xs: 9,
                        sx: {
                            backgroundColor: 'rgb(245, 245, 245)',
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px',
                            padding: '1rem',
                        },
                    }}
                />
            )}
            {filePreview && (filePreviewFiles ? filePreviewFiles.length > 0 : false) && (
                <FilePreviewList
                    files={filePreviewFiles ? filePreviewFiles : []}
                    sx={{
                        display: 'flex',
                        width: 'fit-content',
                        backgroundColor: 'transparent',
                        mb: '5px',
                        p: '15px 10px 5px',
                        gap: '15px',
                        maxWidth: '90%',
                        overflowX: 'scroll',
                        cursor: 'default',
                    }}
                    handleRemoveFile={handleRemoveFile ? handleRemoveFile : () => {}}
                />
            )}
            <Editor
                placeholder={placeholder ? placeholder : ''}
                editorKey={'editor'}
                editorState={editorState}
                onChange={onChange}
                plugins={plugins}
                ref={ref}
                keyBindingFn={keyBindingFn}
                readOnly={readOnly ? readOnly : false}
            />
            <MentionSuggestions
                open={open}
                onOpenChange={onOpenChange}
                onAddMention={onAddMention}
                suggestions={suggestions}
                onSearchChange={onSearchChange}
                entryComponent={MentionUserListItem}
            />
        </div>
    );
};

export default DraftEditor;
