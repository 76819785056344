import React, {
  FunctionComponent,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import { Card, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FeedSocialPost, TYBrandingData } from "../../../../../types/types";
import classnames from "classnames";
import socialPostsService from "../../../../services/socialPostsService";
import { getLikesStr, getCommentsStr } from "../../feedUtils";
import { addNotification } from "../../../../../shared/reducers/notifications/actionTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import { faHeart as faHeartReg } from "@fortawesome/pro-light-svg-icons";
import ItemHeader from "../itemHeader";
import SocialPostLikesModal from "./socialPostLikesModal";
import SocialPostComments from "./socialPostComments";
import Collapse from "../../../common/collapse/collapse";
import FilesDisplay from "../../../common/filesDisplay/filesDisplay";
import FeedPostActions from "../../../../../web/components/feedPage/itemTypes/socialPost/feedPostActions";

const renderHtml = require("html-react-parser");


type SocialPostItemProps = {
  item: FeedSocialPost;
  tyBranding: TYBrandingData;
  setItem: Function;
  setLiked: Function;
  removeItem: Function;
  removeContentByUser: Function;
  isLastItem?: boolean;
};

enum TyCardTypes {
  public = "TYC",
  private = "P2P",
}

const MaxHeight = 300;

const SocialPostItem: FunctionComponent<SocialPostItemProps> = ({
  item,
  isLastItem,
  tyBranding,
  setItem,
  setLiked,
  removeItem,
  removeContentByUser,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.app.user);

  const [likesModalOpen, setLikesModal] = useState(false);
  const [replyOpen, setReplyOpen] = useState(false);
  const [dotsModalOpen, setDotsOpen] = useState(false);
  const [isTooTall, setTooTall] = useState(false);
  const [isCollapsed, setCollapsed] = useState(false);
  const [originalHeight, setOriginalHeight] = useState(MaxHeight);
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    // Check if container is too tall
    const el: any = containerRef.current;
    if (el.clientHeight <= MaxHeight) return;

    setOriginalHeight(el.clientHeight);
    setTooTall(true);
  }, []);

  const getMessageHtml = () => {
    if (!item.model.message) {
      return "";
    }
    return renderHtml(item.model.message);
    
  };

  const renderText = () => {
    if (!isTooTall) return getMessageHtml();

    const heightData = {
      minHeight: MaxHeight,
      maxHeight: originalHeight,
    };
    return (
      <React.Fragment>
        <Collapse
          isOpen={isCollapsed}
          className={"article-body"}
          heightData={heightData}
        >
          {getMessageHtml()}
        </Collapse>
        <span
          className="c-pointer text-primary text-hover"
          onClick={() => setCollapsed(!isCollapsed)}
        >
          {isCollapsed ? t("feed.hide") : t("feed.expand")}
        </span>
      </React.Fragment>
    );
  };

  const handleLike = () => {
    const liked = !item.model.is_liked;

    setLiked(item.model.id, liked);
    socialPostsService.likePost(
      item.model.id,
      liked,
      () => { },
      () => {
        dispatch(
          addNotification({
            label: `Post Like/Dislike`,
            text: t("general.errors.errorLoadingData"),
            type: "danger",
          })
        );
        setLiked(item.model.id, !liked);
      }
    );
  };

  const isTYcard = () => {
    return (
      item.model.post_type === TyCardTypes.public ||
      item.model.post_type === TyCardTypes.private
    );
  };

  const getIconCallback = () => {
    if (!isTYcard() && dotsModalOpen) {
      return setDotsOpen(false);
    } else {
      return isTYcard() ? undefined : setDotsOpen(true);
    }
  };

  const navigateToUser = (e: any, userId: number) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/connect/people/${userId}`);
  };

  const renderPost = () => {
    return (
      <React.Fragment>
        <ItemHeader
          imgUrl={item.model.author.image_url}
          headText={item.model.author.full_name}
          jobTitle={item.model.author.jobtitle}
          time={item.model.created_at_humans || ""}
          iconClass={isTYcard() ? tyBranding.tycIcon : "fal fa-ellipsis-h-alt"}
          iconClickCallback={getIconCallback}
          contact_id={item.model.author ? item.model.author.contact_id : null}
        />
        {dotsModalOpen ? (
          <FeedPostActions
            item={item}
            removeItem={removeItem}
            removeContentByUser={removeContentByUser}
            canDeletePost={item.model.author.id === userInfo.id}
          ></FeedPostActions>
        ) : (
          ""
        )}
        <div
          data-testid="social-post-message"
          className="social-post-message font-secondary-text text-black mt-3 mb-2"
          ref={containerRef}
        >
          {renderText()}
        </div>
        <FilesDisplay
          files={item.model.files}
          className="mb-2"
          socialPost={!isTYcard()}
        >
          {isTYcard() ? (
            <h3 className="mb-3 text-light-black mt-3 font-primary-text">
              {item.model.recipients.map((element: any) => {
                return (
                  <span
                    data-testid="award-sender-name"
                    className="sender-name c-pointer"
                    onClick={(e: any) => navigateToUser(e, element.contact_id)}
                  >
                    @{element.full_name || ""}&nbsp;
                  </span>
                );
              })}
              {`${t("feed.received", {
                text: tyBranding.tycText.btn.value,
              })} ${t("awards.for")} ${item.model.image_title}`}
            </h3>
          ) : (
            ""
          )}
        </FilesDisplay>
        {item.model.manager_name && item.model.manager_name !== "" && item.model.manager_message !== "" ? (
          <div className={"social-post-message mt-3"}>
            <span
              style={{ fontSize: "1.125rem" }}
              className={"text-light-black sender-name font-primary-text"}
            >
              <strong>{t("feed.messageToManager")}</strong>
            </span>
            <div className="mt-2 border-bottom" />
            <div className={"mt-2"}>
              <span className="sender-name text-light-black font-secondary-text">
                {item.model.manager_message}
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <SocialPostLikesModal
        postId={item.model.id}
        isOpen={likesModalOpen}
        toggle={() => {
          setLikesModal(!likesModalOpen);
        }}
      />
      <Card data-testid="social-post-item-card" className={`social-post-item p-3 pb-0 item-container ${isLastItem ? '' : 'mb-3'}`}>
        {isTYcard() &&
          item.additional_model &&
          item.additional_model.social_post &&
          item.additional_model.social_post ? (
          <a
            data-testid="award-link"
            href={`/awards/${item.additional_model.social_post.thank_you_card.id}`}
            className="d-block text-decoration-none"
          >
            {renderPost()}
          </a>
        ) : (
          renderPost()
        )}

        <Row>
          <Col lg={6} xs={6} className="d-flex align-items-center">
            {item.model.likes && item.model.likes > 0 ? (
              <span
                data-testid="total-likes"
                className="me-0 c-pointer d-flex align-items-center"
                onClick={() => setLikesModal(true)}
              >
                <i className="fa-solid fa-circle-heart like-btn me-2"></i>
                <span className="cursor-pointer mr0 d-inline font-secondary-text">
                  {`${item.model.likes} ${getLikesStr(item.model.likes || 0)}`}
                </span>
              </span>
            ) : (
              ""
            )}
          </Col>
          <Col lg={6} xs={6} className="d-flex justify-content-end">
            {item.model.comments && item.model.comments > 0 ? (
              <span className="d-flex align-items-center font-secondary-text">
                <i className="fa-solid fa-message-lines comment-btn-primary comment-btn me-2"></i>
                {`${item.model.comments} ${getCommentsStr(
                  item.model.comments || 0
                )}`}
              </span>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Row className="mt-3 border-top py-2">
          <Col lg={4} xs={4} className="d-flex justify-content-center align-items-center">
            <div data-testid="like-btn" onClick={handleLike}>
              <FontAwesomeIcon
                icon={item.model.is_liked ? faHeart : faHeartReg}
                style={{ color: item.model.is_liked ? "#d9534f" : "inherit" }}
                className="c-pointer media-option"
              />
              <span className="ms-2 me-0 c-pointer text-light-black media-option" style={{ fontWeight: "bold" }}>{t("general.like")}</span>
            </div>
          </Col>

          <Col lg={4} xs={4} className="d-flex justify-content-center align-items-center">
            <div data-testid="comment-btn" onClick={() => setReplyOpen(!replyOpen)}>
              <i className="fa-regular fa-message-lines comment-btn c-pointer media-option"></i>
              <span className={classnames("ms-2 me-0 text-light-black c-pointer media-option")} style={{ fontWeight: "bold" }}>
                {t("general.comment")}
              </span>
            </div>
          </Col>

          <Col lg={4} xs={4} className="d-flex justify-content-center align-items-center" sm></Col>
        </Row>

        <SocialPostComments
          item={item}
          setItem={setItem}
          addReplyOpen={replyOpen}
          setReplyOpen={setReplyOpen}
        />
      </Card>
    </React.Fragment>
  );
};

export default SocialPostItem;
