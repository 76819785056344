export default {
  "sharePost": "Inizia a condividere...",
  "what do you have to say": "Cosa hai da dire",
  "emptyField": "Impossibile salvare un post social vuoto.",
  "socialPost": "Post social",
  "postedFail": "Impossibile pubblicare post sui social, riprova più tardi.",
  "mediaFail": "Si è verificato un errore durante il caricamento dei file multimediali, riprova più tardi.",
  "close": "Chiudere",
  "mediaTooBig": "Alcuni file superavano la dimensione massima e non potevano essere caricati. La dimensione massima del file è",
  "mentionUsers": "Menziona gli utenti",
  "mentionUsersFail": "Impossibile recuperare le menzioni degli utenti, riprova più tardi.",
  "userUnavailable": "Utente non disponibile"
};