import React from 'react';
import { Grid, Drawer, Box, Typography, Button } from '@mui/material';
import { Select } from '../../components/select/select';
import { SelectChangeEvent } from '@mui/material/Select';
import { UseInfiniteQueryResult } from 'react-query';
import { useTranslation } from 'react-i18next';
import { IconCard } from '../../components/IconCard/iconCard';
import SpinnerLoad from '../../components/common/spinnerLoad/spinnerLoad';
import { isWhite } from '../../utils/colourCheck';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export type PeopleFiltersProps = {
    badgeOptions: UseInfiniteQueryResult<any, unknown>;
    managerOptions: UseInfiniteQueryResult<any, unknown>;
    locationOptions: UseInfiniteQueryResult<any, unknown>;
    filtersSearch: any;
    handleFiltersSearchChange: ((e: React.ChangeEvent<HTMLInputElement>, selectType: boolean) => void) | undefined;
    selectedFilters: any;
    setSelectedFilters: React.Dispatch<any>;
    setCustomSelectedFilters: React.Dispatch<any>;
    openAdditionalFilters: boolean;
    setOpenAdditionalFilters: React.Dispatch<React.SetStateAction<boolean>>;
    customSelectedFilters?: any;
    customFilterOptions?: any;
    setFilterID?: React.Dispatch<React.SetStateAction<string>>;
    loadingAttributes?: boolean;
    handleCustomFilterSearchChange?: ((e: React.ChangeEvent<HTMLInputElement>, selectType: boolean) => void) | undefined;
    fetchNextAttributes?: any;
    fetchingNextAtrributes?: boolean;
    customFilterSearch?: any;
    handleClearFilter: () => void;
    displayClearAllFilters: boolean;
};

export const PeopleFilters = React.memo((props: PeopleFiltersProps) => {
    const {
        badgeOptions,
        managerOptions,
        locationOptions,
        filtersSearch,
        handleFiltersSearchChange,
        selectedFilters,
        setSelectedFilters,
        openAdditionalFilters,
        setOpenAdditionalFilters,
        customSelectedFilters,
        setCustomSelectedFilters,
        customFilterOptions,
        setFilterID,
        loadingAttributes,
        handleCustomFilterSearchChange,
        customFilterSearch,
        fetchNextAttributes,
        fetchingNextAtrributes,
        handleClearFilter,
        displayClearAllFilters,
    } = props;

    const tenantBranding = useSelector((state: any) => state.app.branding);
    const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
    const bgColor = isHeaderWhite ? tenantBranding.web_navbar_foreground : tenantBranding.web_navbar_background;
    const fgColor = isHeaderWhite ? tenantBranding.web_navbar_background : tenantBranding.web_navbar_foreground;
    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { t } = useTranslation();

    const updateOptions = (options: any, key: string, value: string) => {
        if (options && options.length === 0) return [];
        let data = options?.pages?.map((page: any) => page).flat();
        return data?.map((option: any) => ({
            id: option[key],
            title: option[value],
        }));
    };

    const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
        event.stopPropagation();
        setSelectedFilters((prev: any) => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            };
        });
    };

    const handleCustomSelectChange = (event: SelectChangeEvent<string[]>, multiple: boolean) => {
        setCustomSelectedFilters((prev: any) => {
            const currentValue = event.target.value;
            const currentFilterValue = customSelectedFilters[event.target.name]?.value;
            const newValue = multiple ? currentValue : currentValue[0] === currentFilterValue?.[0] ? [] : [currentValue];

            return {
                ...prev,
                [event.target.name]: {
                    ...prev[event.target.name],
                    value: newValue,
                },
            };
        });
    };

    const defaultFilterSettings = (key: string, type: string): any => {
        const options: any = {
            manager: managerOptions,
            badge: badgeOptions,
            location: locationOptions,
        };

        const option: any = options[key];

        if (option) {
            switch (type) {
                case 'data':
                    return option.data?.pages.map((page: any) => page.data).flat() || [];
                case 'isLoading':
                    return option.isLoading;
                case 'isFetchingNextPage':
                    return option.isFetchingNextPage;
                case 'fetchNextPage':
                    return option.fetchNextPage;
            }
        }

        return [];
    };

    const drawerSelectStyle = (updateDrawerStyle: boolean): any => {
        return updateDrawerStyle
            ? {
                  selectSx: {
                      backgroundColor: 'white',
                  },
                  inputLabelSx: {
                      marginLeft: '10px',
                  },
                  iconStyle: { marginLeft: 0, marginRight: '10px' },
                  selectContainerSx: { width: '100%', padding: '0 5px' },
              }
            : {
                  iconStyle: { right: '5px' },
              };
    };

    const renderFilters = (filters: any, inDrawer: boolean) => {
        if (!filters) return null;
        return Object.keys(filters).map((filter: string, index: number) => {
            let checkOptionsAvaible = Array.isArray(filters[filter]);
            let drawerSelectStyleProps = drawerSelectStyle(openAdditionalFilters && inDrawer);
            return (
                <Select
                    key={index}
                    displaySearchField
                    inputLabel
                    label={t(`connect.${filter}`) as string}
                    name={filter}
                    multiple={true}
                    selectedValue={checkOptionsAvaible ? selectedFilters[filter] ?? [] : []}
                    setSelectedValue={checkOptionsAvaible ? setSelectedFilters : () => {}}
                    handleChange={handleSelectChange}
                    searchFieldProps={{
                        searchQuery: filtersSearch[filter],
                        handleSearch: (e) => {
                            handleFiltersSearchChange && handleFiltersSearchChange(e, checkOptionsAvaible);
                        },
                        sx: { margin: '10px 5px 5px', height: '35px', borderRadius: '8px' },
                        placeholder: t(`connect.search`) as string,
                        name: filter,
                        handleKeyDown: (e) => {
                            e.stopPropagation();
                        },
                    }}
                    handleScroll={(e) => {
                        e.stopPropagation();
                        const { scrollTop, scrollHeight, clientHeight } = e.target;
                        if (Math.round(scrollTop + clientHeight) + 1 >= scrollHeight) {
                            defaultFilterSettings(filter, 'fetchNextPage')();
                        }
                    }}
                    {...drawerSelectStyleProps}
                    loading={checkOptionsAvaible ? defaultFilterSettings(filter, 'isLoading') : false}
                    loadingNextPage={checkOptionsAvaible ? defaultFilterSettings(filter, 'isFetchingNextPage') : false}
                    selectContainerSx={{ width: '100%' }}
                    options={checkOptionsAvaible ? defaultFilterSettings(filter, 'data') : []}
                    clearSelection={inDrawer}
                    handleClearSelection={() => {
                        handleFiltersSearchChange && handleFiltersSearchChange({ target: { value: '', name: filter } } as any, checkOptionsAvaible);
                        setSelectedFilters((prev: any) => ({ ...prev, [filter]: typeof filters[filter] === 'object' ? [] : '' }));
                    }}
                    textOnly={!checkOptionsAvaible}
                />
            );
        });
    };

    const renderCustomFilters = (filters: any) => {
        if (!filters) return null;
        return Object.keys(filters).map((filter: any, index: number) => {
            let checkOptionsAvaible = Array.isArray(filters[filter].value);
            let drawerSelectStyleProps = drawerSelectStyle(true);
            return (
                <Select
                    key={index}
                    displaySearchField
                    inputLabel
                    label={filters[filter].title}
                    name={filters[filter].id}
                    multiple={filters[filter].multiple}
                    selectedValue={checkOptionsAvaible ? filters[filter].value ?? [] : []}
                    setSelectedValue={checkOptionsAvaible ? setCustomSelectedFilters : () => {}}
                    handleChange={(e) => handleCustomSelectChange(e, filters[filter].multiple)}
                    searchFieldProps={{
                        searchQuery: customFilterSearch[filter],
                        handleSearch: (e) => {
                            handleCustomFilterSearchChange && handleCustomFilterSearchChange(e, checkOptionsAvaible);
                        },
                        sx: { margin: '10px 5px 5px', height: '35px', borderRadius: '8px' },
                        placeholder: t(`connect.search`) as string,
                        name: filter,
                        handleKeyDown: (e) => {
                            e.stopPropagation();
                        },
                    }}
                    textOnly={!checkOptionsAvaible}
                    onOpen={() => (checkOptionsAvaible ? setFilterID && setFilterID(filters[filter].id) : () => {})}
                    handleOptionClick={() => {}}
                    {...drawerSelectStyleProps}
                    loading={checkOptionsAvaible ? customFilterOptions?.isLoading : false}
                    selectContainerSx={{ width: '100%' }}
                    clearSelection={true}
                    handleClearSelection={() => {
                        handleCustomFilterSearchChange &&
                            handleCustomFilterSearchChange({ target: { value: '', name: filter } } as any, checkOptionsAvaible);
                        setCustomSelectedFilters((prev: any) => ({
                            ...prev,
                            [filter]: {
                                value: typeof filters[filter].value === 'object' ? [] : '',
                                multiple: filters[filter].multiple,
                                title: filters[filter].title,
                                id: filters[filter].id,
                            },
                        }));
                    }}
                    options={checkOptionsAvaible ? customFilterOptions && updateOptions(customFilterOptions.data, 'key', 'value') : []}
                />
            );
        });
    };

    return (
        <Grid container>
            {!isXsScreen && (
                <Grid
                    item
                    xs={11}
                    display={'flex'}
                    flexDirection={'row'}
                    rowSpacing={2}
                    sx={{
                        gap: '10px',
                    }}
                >
                    {renderFilters(selectedFilters, false)}
                </Grid>
            )}
            <Grid item xs={isXsScreen ? 12 : 1} sx={{ paddingRight: isXsScreen ? '5px' : '10px', paddingTop: isXsScreen ? '0' : '10px' }}>
                {isXsScreen ? (
                    <Button
                        onClick={() => setOpenAdditionalFilters(true)}
                        fullWidth
                        sx={{
                            color: fgColor,
                            fontSize: '14px',
                            fontWeight: '600',
                            backgroundColor: bgColor,
                            m: 0,
                            textTransform: 'none',
                            letterSpacing: '1px',
                            ':hover': {
                                backgroundColor: bgColor,
                            },
                        }}
                    >
                        {t('connect.filters')}
                        <i className='fa-regular fa-bars-filter' style={{ fontSize: '16px', color: fgColor, paddingLeft: '10px' }} />
                    </Button>
                ) : (
                    <i
                        className='fa-solid fa-bars-filter'
                        style={{
                            fontSize: '20px',
                            color: 'black',
                            cursor: 'pointer',
                            width: '100%',
                            textAlign: 'right',
                        }}
                        onClick={() => setOpenAdditionalFilters(true)}
                    />
                )}
                <Drawer
                    open={openAdditionalFilters}
                    onClose={() => setOpenAdditionalFilters(false)}
                    anchor='right'
                    PaperProps={{
                        sx: {
                            width: '400px',
                            padding: '10px',
                            marginTop: '70px',
                            background: bgColor,
                            color: fgColor,
                        },
                    }}
                >
                    <IconCard
                        handleClick={() => {}}
                        icon={
                            <div className={'me-2 d-flex justify-content-center align-items-center'}>
                                <i className='fa-regular fa-bars-filter' style={{ fontSize: '22px', color: fgColor }} />
                            </div>
                        }
                        sx={{
                            background: bgColor,
                            color: fgColor,
                            padding: '10px 10px',
                        }}
                        title={t('connect.filters')}
                        titleStyle={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '20px',
                            fontWeight: '600',
                            width: '100%',
                        }}
                    >
                        <Box sx={{ display: 'flex', gap: '15px' }}>
                            {displayClearAllFilters && (
                                <Typography
                                    sx={{ color: fgColor, fontSize: '15px', fontWeight: '500', cursor: 'pointer' }}
                                    onClick={handleClearFilter}
                                >
                                    {t('connect.clearFilters')}
                                </Typography>
                            )}
                            <i
                                className='fa-regular fa-close'
                                style={{ fontSize: '20px', cursor: 'pointer', color: fgColor }}
                                onClick={() => setOpenAdditionalFilters(false)}
                            ></i>
                        </Box>
                    </IconCard>
                    <Box
                        sx={{
                            margin: '10px 0px',
                            padding: '0 15px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            height: '82vh',
                            overflow: 'hidden',
                            overflowY: 'scroll',
                            scrollbarColor: 'white grey',
                            scrollbarWidth: 'thin',
                        }}
                        onScroll={(e: any) => {
                            e.stopPropagation();
                            const { scrollTop, scrollHeight, clientHeight } = e.target;
                            if (Math.round(scrollTop + clientHeight) + 1 >= scrollHeight) {
                                fetchNextAttributes && fetchNextAttributes();
                            }
                        }}
                    >
                        {loadingAttributes ? (
                            <SpinnerLoad />
                        ) : (
                            <>
                                {renderFilters(selectedFilters, true)}
                                {renderCustomFilters(customSelectedFilters)}
                                {fetchingNextAtrributes && <SpinnerLoad />}
                            </>
                        )}
                    </Box>
                </Drawer>
            </Grid>
        </Grid>
    );
});
