export default {
  "sharePost": "Commencez à partager...",
  "what do you have to say": "Qu'est-ce que tu as à dire",
  "emptyField": "Impossible d'enregistrer une publication sociale vide.",
  "socialPost": "Publication sociale",
  "postedFail": "La publication sur les réseaux sociaux n'a pas pu être publiée, veuillez réessayer ultérieurement.",
  "mediaFail": "Une erreur s'est produite lors du téléchargement du contenu multimédia. Veuillez réessayer ultérieurement.",
  "close": "Fermer",
  "mediaTooBig": "Certains fichiers ont dépassé la taille maximale et n'ont pas pu être chargés. La taille maximale du fichier est",
  "mentionUsers": "Mentionnez les utilisateurs",
  "mentionUsersFail": "Impossible de récupérer les utilisateurs mentionnés, veuillez réessayer ultérieurement.",
  "userUnavailable": "Utilisateur non disponible"
};