import React, { useCallback, useMemo } from 'react';
import { ChatImageModal } from '../chatImageModal/chatImageModal';
import { Box } from '@mui/system';
import { SxProps } from '@mui/system';
import { Typography } from '@mui/material';
import { iconMappings } from '../filePreviewList/filePreviewList';

export type ChatBubbleImageProps = {
    files: any[];
    sx?: SxProps;
    setDisplayTooltip?: React.Dispatch<React.SetStateAction<boolean>>;
    sendingMessage?: boolean;
};

export const ChatBubbleImage = (props: ChatBubbleImageProps) => {
    const { files, sx, setDisplayTooltip, sendingMessage } = props;
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [currentImageIndex, setCurrentImageIndex] = React.useState<number>(0);
    const [currentImageUrl, setCurrentImageUrl] = React.useState<string>('');

    const calculateImageSize = useMemo(() => {
        let height = '200px';
        let width = '100%';
        if (files?.length > 3) {
            height = '100px';
            width = '25%';
        } else if (files?.length === 3) {
            height = '150px';
            width = '33%';
        } else if (files?.length === 2) {
            height = '200px';
            width = '50%';
        }
        return {
            height,
            width,
        };
    }, [files?.length]);

    const getFileIcon = useCallback((file: File): string => {
        const fileExtension = file.name.split('.').pop();
        const defaultIcon = 'text.png';
        if (!fileExtension) return defaultIcon;
        else return iconMappings[fileExtension.toLowerCase()] || defaultIcon;
    }, []);

    const handleFilePreview = (file: any) => {
        if (file?.preview.includes('blob:') && !file?.type.includes('image')) {
            return `${process.env.REACT_APP_CMS_API_URL}/images/icons/${getFileIcon(file)}`;
        } else {
            return file?.preview;
        }
    };

    return (
        <Box sx={{ display: 'flex', gap: '5px', ...sx }} data-testid="chat-bubble-img-container">
            {files?.slice(0, 4).map((file: any, index: number) => (
                <>
                    <Box
                        sx={{
                            width: calculateImageSize?.width,
                            height: calculateImageSize?.height,
                            cursor: sendingMessage === true ? 'default' : 'pointer',
                            position: 'relative',
                        }}
                        onClick={() => {
                            if (sendingMessage === true) return;
                            setIsOpen(true);
                            setCurrentImageIndex(index);
                            setDisplayTooltip && setDisplayTooltip(false);
                            setCurrentImageUrl(file?.type.includes('image') || file?.type.includes('video') ? file?.path : file?.preview);
                        }}
                        key={index}
                        data-testid="chat-bubble-img"
                    >
                        <img
                            src={handleFilePreview(file)}
                            className="chat-bubble-img"
                            style={{
                                filter: files?.length > 4 && index === 3 ? 'brightness(0.5)' : 'none',
                                height: file?.type.includes('image') || file?.type.includes('video') ? '100%' : '75%',
                                padding: file?.type.includes('image') || file?.type.includes('video') ? '0px' : '25px',
                            }}
                        />
                        {!file?.type.includes('image') && !file?.type.includes('video') && (
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    fontWeight: '600',
                                    maxHeight: '34px',
                                }}
                            >
                                {file?.name}
                            </Typography>
                        )}
                        {file?.type.includes('video') && (
                            <Box className="chat-bubble-img-placeholder-text" data-testid="chat-bubble-video-placeholder-text">
                                <span className="fa-regular fa-play" style={{ fontSize: '30px' }}></span>
                            </Box>
                        )}
                        {files?.length > 4 && index === 3 && (
                            <Box className="chat-bubble-img-placeholder-text" data-testid="chat-bubble-img-placeholder-text">
                                +{files?.length - 4}
                            </Box>
                        )}
                    </Box>
                </>
            ))}
            <ChatImageModal
                isOpen={isOpen}
                image_url={currentImageUrl}
                handleClose={() => setIsOpen(false)}
                files={files}
                imageIndex={currentImageIndex}
            />
        </Box>
    );
};
