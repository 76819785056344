export default {
    "sharePost": "Comienza a compartir...",
    "what do you have to say": "Que tienes que decir",
    "emptyField": "No se puede guardar una publicación vacía.",
    "socialPost": "Publicación ",
    "postedFail": "La publicación no ha podido ser guardada, inténtelo de nuevo más tarde.",
    "mediaFail": "Se ha producido un error al subir los archivos, por favor inténtelo de nuevo más tarde.",
    "close": "Cerrar",
    "mediaTooBig": "Algunos ficheros sobrepasaron el máximo permitido y no pudieron ser subidos. Tamaño máximo por fichero ",
    "mentionUsers": "Mencionar usuarios",
    "mentionUsersFail": "No se pudieron encontrar los usuarios mencionados, por favor, inténtalo de nuevo más tarde.",
    "userUnavailable": "Usuario no disponible"
};