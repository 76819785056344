
/**
 * @description Format date time to human readable short time format (e.g. Jan 1, 12:34)
 * @param createdAt: string - date time string 
 * @returns: string - formatted date time string
 */
const formatShortDateTime = (createdAt: string, showYear: boolean = false): string => {
    const date = new Date(createdAt);
    const day = date.getDate();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const monthText = new Intl.DateTimeFormat('en', { month: 'short' }).format(new Date(createdAt));
    const year = date.getFullYear();
    return `${monthText} ${day}${showYear ? ` ${year}` : ''}, ${hours}:${minutes}`;
}

export {
    formatShortDateTime
}