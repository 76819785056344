import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Col, Modal, ModalHeader, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { SearchHook, useSearchHook } from '../../hooks/useSearchHook';
import { useDebounce } from '../../hooks/useDebounceHook';
import './manageParticipantModal.scss';
import SpinnerLoad from '../common/spinnerLoad/spinnerLoad';
import { IconCard } from '../IconCard/iconCard';
import ScrollbarComponent from '../common/scrollbar/scrollbar';
import { GroupParticipantTab } from '../../../types/interface/Thread';
import { useAddAdminHook, useAddParticipant, useRemoveAdminHook, useRemoveParticipant } from '../../hooks/useManageGroupParticipantHook';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';
import { isWhite } from '../../utils/colourCheck';
import { fetchContacts, fetchParticipants } from '../../pages/Chat/chatProvider';

export type ManageParticipantModalProps = {
    openManageParticipantModal: boolean;
    participantSearchString?: string;
    setOpenManageParticipantModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ManageParticipantModal: React.FC<ManageParticipantModalProps> = ({
    openManageParticipantModal,
    participantSearchString,
    setOpenManageParticipantModal,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const tenantBranding = useSelector((state: any) => state.app.branding);
    const loggedInUser = useSelector((state: any) => state.app.user);
    const { id: threadID } = useParams<{ id: string }>();
    const {
        searchQuery: contactSearchQuery,
        handleChange: handleSearchContact,
        handleSearchReset: resetSearchContact,
    }: SearchHook = useSearchHook(undefined);
    const { searchQuery: adminSearchQuery, handleChange: handleSearchAdmin, handleSearchReset: resetSearchAdmin }: SearchHook = useSearchHook('');
    const { debouncedValue: debouncedContactSearchQuery } = useDebounce(contactSearchQuery);
    const { debouncedValue: debouncedAdminSearchQuery } = useDebounce(adminSearchQuery);
    const [loaderUserId, setLoaderUserId] = useState<number>(0);
    const [activeTab, setActiveTab] = useState<GroupParticipantTab>({
        participants: true,
        admins: false,
    });

    const fetchAllParticipants = () => {
        if (hasNextParticipantPage) {
            if (fetchNextParticipantPage) {
                fetchNextParticipantPage();
            }
        }
    };

    const { mutate: addParticipantMutate } = useAddParticipant(
        () => {
            handleInvalidateQueries();
            setLoaderUserId(0);
        },
        () => {
            setLoaderUserId(0);
            dispatch(
                addNotification({
                    label: t('chat.addParticipant'),
                    text: t('chat.addParticipantErrorMsg'),
                    type: 'danger',
                })
            );
        }
    );

    const { mutate: removeParticipantMutate } = useRemoveParticipant(
        () => {
            handleInvalidateQueries();
            setLoaderUserId(0);
        },
        () => {
            setLoaderUserId(0);
            dispatch(
                addNotification({
                    label: t('chat.removeParticipant'),
                    text: t('chat.removeParticipantErrorMsg'),
                    type: 'danger',
                })
            );
        }
    );

    const { mutate: addAdminMutate } = useAddAdminHook(
        () => {
            handleInvalidateQueries();
            setLoaderUserId(0);
        },
        () => {
            setLoaderUserId(0);
            dispatch(
                addNotification({
                    label: t('chat.addAdmin'),
                    text: t('chat.addAdminErrorMsg'),
                    type: 'danger',
                })
            );
        }
    );

    const { mutate: removeAdminMutate } = useRemoveAdminHook(
        () => {
            handleInvalidateQueries();
            setLoaderUserId(0);
        },
        () => {
            setLoaderUserId(0);
            dispatch(
                addNotification({
                    label: t('chat.removeAdmin'),
                    text: t('chat.removeAdminErrorMsg'),
                    type: 'danger',
                })
            );
        }
    );

    const handleInvalidateQueries = (value: string = '', isOnChangeAdminEvent: boolean = false) => {
        queryClient.invalidateQueries(['manageContacts', debouncedContactSearchQuery, true]);
        queryClient.invalidateQueries(['manageParticipants', threadID, isOnChangeAdminEvent ? value : debouncedAdminSearchQuery]);
        queryClient.invalidateQueries(['participants', threadID, participantSearchString]);
    };

    const handleSelectedParticipantContacts = (userId: number, isAdmin: boolean = false): boolean => {
        return (
            participantsData?.pages?.some((userGroup) => {
                return userGroup.data.some((user: any) => {
                    if (activeTab.participants && !isAdmin) {
                        return user.id === userId;
                    } else {
                        return user.id === userId && user.is_admin;
                    }
                });
            }) || false
        );
    };

    const {
        data: participantsData,
        isLoading: fetchingParticipants,
        hasNextPage: hasNextParticipantPage,
        fetchNextPage: fetchNextParticipantPage,
    } = useInfiniteQuery(
        ['manageParticipants', threadID, debouncedAdminSearchQuery],
        ({ pageParam = 1 }) => fetchParticipants(threadID, pageParam, debouncedAdminSearchQuery),
        {
            enabled: openManageParticipantModal || activeTab.admins,
            getNextPageParam: (lastPage, allPages) => {
                if (lastPage) {
                    if (lastPage.meta.current_page === lastPage.meta.last_page) return undefined;
                    return allPages.length + 1;
                }
            },
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 10 * 2, // 20 minutes
        }
    );

    useEffect(() => {
        if (openManageParticipantModal) fetchAllParticipants();
    }, [openManageParticipantModal, participantsData, participantsData?.pages, participantsData?.pages?.length]);

    const {
        isLoading: isFetchingContacts,
        data: contactsData,
        hasNextPage: hasNextContactPage,
        fetchNextPage: fetchContactNextPage,
        isFetchingNextPage: fetchingNextContactPage,
    } = useInfiniteQuery(
        ['manageContacts', debouncedContactSearchQuery, true],
        ({ pageParam = 1 }) => fetchContacts(debouncedContactSearchQuery, true, pageParam),
        {
            enabled: activeTab.participants,
            getNextPageParam: (lastPage, pages) => {
                if (lastPage?.links?.next) return pages.length + 1;
                return undefined;
            },
            refetchOnWindowFocus: false,
        }
    );

    const pageScroll = (e: { scrollTop: number; clientHeight: number; scrollHeight: number }) => {
        if (!isFetchingContacts && hasNextContactPage && Math.ceil(e.scrollTop + e.clientHeight) >= e.scrollHeight) {
            if (activeTab.participants) {
                if (fetchContactNextPage) fetchContactNextPage();
            }
        }
    };

    const handleChange = async (user: any) => {
        if (activeTab.participants) {
            if (user.participated) {
                // Remove Participant API
                await removeParticipantMutate({
                    userId: user.id,
                    threadId: threadID,
                });
            } else {
                // Add Participant API
                await addParticipantMutate({
                    threadId: threadID,
                    body: {
                        user_id: user.id,
                    },
                });
            }
        }

        if (activeTab.admins) {
            if (user.participated) {
                // Remove Admin API
                await removeAdminMutate({
                    threadId: threadID,
                    userId: user.id,
                });
            } else {
                // Add Admin API
                await addAdminMutate({
                    threadId: threadID,
                    userId: user.id,
                });
            }
        }
    };

    const renderUserList = (userGroup: any) =>
        userGroup?.map((group: any, index: number) => {
            return (
                <React.Fragment key={index}>
                    {group?.data.map((contact: any) => {
                        return (
                            <IconCard
                                data-testid={`user-${contact.id}`}
                                title={contact?.full_name}
                                icon={activeTab.participants ? contact?.image_url : contact?.avatar_url}
                                key={contact?.id}
                                sx={{
                                    direction: 'ltr',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    fontFamily: 'Gilroy',
                                    padding: '10px 10px',
                                    ':hover': {
                                        backgroundColor: '#F4F4F4',
                                        borderRadius: '8px',
                                    },
                                }}
                                titleStyle={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    ml: '5px',
                                }}
                                children={
                                    loggedInUser.id != contact.id && (
                                        <input
                                            id={`user-${contact.id}-checkbox`}
                                            type='checkbox'
                                            className='me-3 manage-participant-checkbox form-check-input c-pointer'
                                            checked={handleSelectedParticipantContacts(contact.id)}
                                            disabled={
                                                (
                                                    activeTab.participants
                                                        ? contact.id === loaderUserId || handleSelectedParticipantContacts(contact.id, true)
                                                        : contact.id === loaderUserId
                                                )
                                                    ? true
                                                    : false
                                            }
                                            style={{
                                                backgroundColor: handleSelectedParticipantContacts(contact.id) ? checkBoxBg : 'initial',
                                                borderColor: handleSelectedParticipantContacts(contact.id) ? checkBoxBg : 'initial',
                                            }}
                                        />
                                    )
                                }
                                handleClick={() => {
                                    if (
                                        !(activeTab.participants
                                            ? contact.id === loaderUserId || handleSelectedParticipantContacts(contact.id, true)
                                            : contact.id === loaderUserId) &&
                                        loggedInUser.id != contact.id
                                    ) {
                                        setLoaderUserId(contact.id);
                                        handleChange({ ...contact, participated: handleSelectedParticipantContacts(contact.id) });
                                    }
                                }}
                            />
                        );
                    })}
                    {group?.data.length === 0 && <p className='m-0 mt-2 w-100 d-flex justify-content-center'>{t('connect.noResultsFound')}</p>}
                </React.Fragment>
            );
        });

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (activeTab.participants) {
            if (handleSearchContact) handleSearchContact(e);
        }

        if (activeTab.admins) {
            if (handleSearchAdmin) handleSearchAdmin(e);
        }

        handleInvalidateQueries(e.target.value, true);
    };

    const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
    const checkBoxBg = isHeaderWhite ? tenantBranding.web_navbar_foreground : tenantBranding.web_navbar_background;

    return (
        <Modal isOpen={openManageParticipantModal} className='manage-participant-modal' data-testid='manage-participant-modal'>
            <ModalHeader className='w-100'>
                <Row className='w-100'>
                    <Col lg={12} xs={12} className='d-flex'>
                        <h3 className='manage-participant-title mt-1'>
                            {activeTab.participants ? t('chat.manageParticipants') : t('chat.manageAdmins')}
                        </h3>
                    </Col>
                </Row>
                <div
                    onClick={() => setOpenManageParticipantModal(false)}
                    data-testid='modal-close-btn'
                    className='modal-close-btn d-flex align-items-center c-pointer'
                >
                    <svg xmlns='http://www.w3.org/2000/svg' id='Outline' viewBox='0 0 24 24' width='30' height='30'>
                        <path d='M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z' />
                    </svg>
                </div>
            </ModalHeader>
            <Box className='box'>
                <div className='btn-group'>
                    <button
                        type='button'
                        className={`btn participant-btn ${activeTab.participants ? 'active-tab' : ''}`}
                        onClick={() => {
                            if (resetSearchAdmin) resetSearchAdmin();
                            handleInvalidateQueries('', true);
                            setActiveTab({
                                participants: true,
                                admins: false,
                            });
                        }}
                    >
                        {t('chat.participants')}
                    </button>
                    <button
                        type='button'
                        className={`btn admins-btn ${activeTab.admins ? 'active-tab' : ''}`}
                        onClick={() => {
                            if (resetSearchContact) resetSearchContact();
                            handleInvalidateQueries('', true);
                            setActiveTab({
                                participants: false,
                                admins: true,
                            });
                        }}
                    >
                        {t('chat.admins')}
                    </button>
                </div>
            </Box>
            <Box className='search-box'>
                <div className='search-section d-flex align-items-center'>
                    <p className='fa-regular form-control search-icon fa-magnifying-glass m-0'></p>
                    <input
                        type='text'
                        className='participants-search'
                        placeholder={`${t('chat.searchContacts')}`}
                        value={activeTab.participants ? contactSearchQuery : adminSearchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
            </Box>
            <Box className='user-list-box'>
                <ScrollbarComponent onScroll={pageScroll} rightScroll={true} noScrollX>
                    {activeTab.participants ? renderUserList(contactsData?.pages) : renderUserList(participantsData?.pages)}
                    {(fetchingNextContactPage || isFetchingContacts) && (
                        <SpinnerLoad className='w-100 d-flex align-items-center justify-content-center' size={50} />
                    )}
                </ScrollbarComponent>
            </Box>
        </Modal>
    );
};

export default ManageParticipantModal;
