import React, { useState, useEffect, useRef } from 'react';
import { AxiosResponse } from 'axios';
import { Row, Col } from 'reactstrap';
import ConnectPage from './../connectPage';
import connectService from '../../../services/connectService';
import AwardsModalComponent from '../../common/modal/awardsModal';
import { useTranslation } from 'react-i18next';
import Posts from './postsPage';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import './contactPage.scss';
import '../connectPage.scss';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import Spinner from '../../common/spinnerLoad/spinnerLoad';
import OrgChartModalComponent from '../../common/modal/orgChartModal';
import { ContactAttrDetails, ContactUserData, ContactUserMainDetails, DynamicAttributesData, OrgChartData } from '../../../../types/types';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import ContactEditProfilePage from '../contactPage/contactEditProfilePage';
import AnalyticsComponent from '../../../components/common/analytics/analyticsComponent';
import FollowingDetailModal from '../../followingDetailModal/followingDetailModal';
import { isWhite } from '../../../utils/colourCheck';
import { faAngleDown, faBookOpenReader, faSitemap } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SendThankYouButton from '../../common/sendThankYouButton/sendThankYouButton';
import { Box, Typography } from '@mui/material';
import { IconCard } from '../../IconCard/iconCard';
import { faPenCircle } from '@fortawesome/pro-regular-svg-icons';
import { OrgChart } from '../../orgChart/orgChart';

type TParams = { id: string; mode: string };

export type PostTabDetailType = {
    postData: Array<any>;
    pageNo: number;
    nextPageAvailable: boolean;
    isLoadingMore: boolean;
    isPostsLoading: boolean;
    isInitialDataLoad: boolean;
};

export type ContactDetailsPageProps = {
    hideContactList?: boolean;
};

function ContactDetailsPage(props: ContactDetailsPageProps) {
    let { id, mode } = useParams<TParams>();
    const { state, search } = useLocation();
    const { hideContactList } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const tenantBranding = useSelector((state: any) => state.app.branding);
    const profileContainerRef = useRef<HTMLDivElement>(null);
    const {
        tycText: {
            btn: { value: awardsConfigName },
        },
        button: p2pBtn,
    } = useSelector((state: any) => state.app.tenant.tyBranding);
    const [contactAttrDetails, setContactAttrDetails] = useState<ContactAttrDetails>();
    const [loggedInUserId, setLoggedInUserId] = useState<number>(0);
    const [loggedInUserContactId, setLoggedInUserContactId] = useState<number>(0);
    const [contactDetailsData, setContactDetailsData] = useState<boolean>(true);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [showResults, setShowResults] = useState<boolean>(false);
    const [tabActive, setTabActive] = useState<number>(1);
    const [userDetail, setUserDetails] = useState<ContactUserData>();
    const [mainDetail, setMainDetails] = useState<ContactUserMainDetails>();
    const [isAwardOpen, setIsAwardOpen] = useState<boolean>(false);
    const [isOpenChart, setIsOpenChart] = useState<boolean>(false);
    const [toggleAlias, setToggleAlias] = useState<boolean>(false);
    const [toggleSubAlias, setToggleSubAlias] = useState<boolean>(false);
    const [contactUserId, setContactUserId] = useState<string>('');
    const [loggedInUserLocationId, setLoggedInUserLocationId] = useState<string>('');
    const [selectedInUserId, setSelectedInUserId] = useState<string>('');
    const [headerName, setHeaderName] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [removeFav, setRemoveFav] = useState<string>('');
    const [addFav, setAddFav] = useState<string>('');
    const [isFav, setIsFav] = useState<boolean>(false);
    const tenantInfo = useSelector((state: any) => state.app.tenant);
    const userInfo = useSelector((state: any) => state.app.user);
    const tyBranding = useSelector((state: any) => state.app.tenant.tyBranding);
    const [editUserData, setEditUserData] = useState<any>();
    const [activityId, setActivityId] = useState<string>('');
    const [showOrgChart, setShowOrgChart] = useState<boolean>(false);
    const [orgChartData, setOrgChartData] = useState<{ data: OrgChartData } | null>();
    const [openFollowingDetailDialog, setOpenFollowingDetailDialog] = useState<{ open: boolean; defaultTab: number }>({
        open: false,
        defaultTab: 0,
    });
    const [scrollIsBottom, setScrollIsBottom] = useState<boolean>(false);
    const [postTabDetail, setPostTabDetail] = useState<PostTabDetailType>({
        postData: [],
        pageNo: 1,
        nextPageAvailable: true,
        isLoadingMore: false,
        isPostsLoading: false,
        isInitialDataLoad: false,
    });

    useEffect(() => {
        setOpenFollowingDetailDialog({ open: false, defaultTab: 0 });
        setIsLoading(true);
        setUserDetails(undefined);
        setPostTabDetail({
            postData: [],
            pageNo: 1,
            nextPageAvailable: true,
            isLoadingMore: false,
            isPostsLoading: false,
            isInitialDataLoad: false,
        });
        if (id != undefined) {
            setShowResults(false);
            getLoggedInUserData(true);
            if (mode != undefined) {
                getEditData(id);
            } else {
                setEditMode(false);
                if (state?.showOrgChart) {
                    fetchDataById(id, 3);
                } else {
                    fetchDataById(id);
                }
                setContactDetailsData(true);
            }
        } else {
            setEditMode(false);
            getLoggedInUserData();
            setShowResults(false);
            setContactDetailsData(true);
        }
    }, [id, mode, state]);

    const getEditData = (id: string) => {
        connectService.getEditData(id, (resp: AxiosResponse) => {
            setIsLoading(false);
            setEditUserData(resp.data.data);
            setEditMode(true);
        });
    };

    const fetchDataById = (id: string, tab: number = 1) => {
        connectService.getContactById(
            id,
            (resp: AxiosResponse) => {
                setActivityId(resp.data.data.activity_id);
                setIsLoading(false);
                setTabActive(tab);
                setContactAttrDetails(resp.data.data.dynamic_attributes);
                setContactUserId(resp.data.data.user.contact_id);
                setUserDetails(resp.data.data.user);
                setIsFav(resp.data.data.is_favorite);
                setSelectedInUserId(resp.data.data.user.id);
                setMainDetails(resp.data.data);
                if (resp.data.data.manager) {
                    orgChart(resp.data.data.user.id);
                }
                if (tab === 3) {
                    setShowResults(false);
                    setContactDetailsData(false);
                    setShowOrgChart(true);
                } else {
                    setShowOrgChart(false);
                }
            },
            () => {
                navigate('/connect');
            }
        );
    };

    const orgChart = (id: string) => {
        connectService.getUserOrgChartById(id, (resp: { data: OrgChartData | any[] }) => {
            if ((resp.data as any[]).length === 0) {
                setOrgChartData(null);
            } else {
                setOrgChartData(resp as { data: OrgChartData });
            }
        });
    };

    const getLoggedInUserData = (hasParamId = false) => {
        connectService.getLoggedInUserData((resp: AxiosResponse) => {
            setLoggedInUserId(resp.data.data.user.id);
            setLoggedInUserContactId(resp.data.data.user.contact_id);
            if (!hasParamId) {
                setLoggedInUserLocationId(resp.data.data.location_contact_id);
                setContactUserId(resp.data.data.user.contact_id);
                setSelectedInUserId(resp.data.data.user.id);
                fetchDataById(resp.data.data.user.contact_id);
            }
        });
    };

    const onClick = () => {
        setTabActive(2);
        setShowResults(true);
        setContactDetailsData(false);
        setShowOrgChart(false);
    };

    const onClickOrganisation = () => {
        setTabActive(3);
        setShowResults(false);
        setContactDetailsData(false);
        setShowOrgChart(true);
    };

    const onClickDetails = () => {
        setTabActive(1);
        setShowResults(false);
        setContactDetailsData(true);
        setShowOrgChart(false);
    };

    const sendMessage = (id: any) => {
        connectService.getThreadByUserId(id, (resp: AxiosResponse) => {
            //window.location.href = '/messages/thread/' + resp.data.threadId;
            navigate('/chat/' + resp.data.threadId);
        });
    };

    const chartModalTrigger = () => {
        setIsOpenChart(true);
        setHeaderName(`${t('connect.chart')}`);
    };

    const modalAwardTrigger = () => {
        setIsAwardOpen(true);
    };

    const onClickThankYou = () => {
        localStorage.setItem('to_id', userDetail?.id.toString() ?? '');
        localStorage.setItem('to_image', userDetail?.image_url ?? '');
        localStorage.setItem('to_name', userDetail?.full_name ?? '');
        localStorage.setItem('to_manager', userDetail?.has_manager.toString() ?? '');
        window.location.href = '/awards/new';
    };

    const followRequest = (userid: number) => {
        let formData = new FormData();
        formData.append('user_id', userid.toString());
        connectService.followUserRequest(
            userid.toString(),
            formData,
            () => {
                if (id != undefined) {
                    fetchDataById(id);
                }
            },
            () => {}
        );
    };

    const unfollowRequest = (userid: string) => {
        let formData = new FormData();
        formData.append('user_id', userid);
        connectService.unfollowUserRequest(
            userid,
            formData,
            () => {
                if (id != undefined) {
                    fetchDataById(id);
                }
            },
            () => {}
        );
    };

    const snoozeUntil = (userid: string, time: string) => {
        let formData = new FormData();
        formData.append('user_id', userid);
        formData.append('time', time);
        connectService.setSnoozeTime(
            userid,
            formData,
            () => {
                if (id != undefined) {
                    fetchDataById(id);
                }
            },
            () => {}
        );
    };

    const toggleAliasHandleOnClick = () => {
        setToggleAlias(!toggleAlias);
    };

    const toggleSubAliasHandleOnClick = () => {
        setToggleSubAlias(!toggleSubAlias);
    };

    const renderModalData = () => {
        return (
            <ul className='user-awards-list padding-left-20 position-relative list-style-type-none'>
                {userDetail?.awards && userDetail?.awards.length > 0
                    ? userDetail?.awards.map((res, index) => (
                          <li
                              key={res.id}
                              className={`user-award-list-item d-flex justify-content-space-between ${index == 0 ? '' : 'mt-3'} pb-3 ${
                                  index == userDetail?.awards?.length - 1 ? '' : 'border-bottom'
                              }`}
                          >
                              <div className='user-award-container d-flex justify-content-space-between w-100'>
                                  <div className='me-3 modal-image-section'>
                                      <img src={res.preview_image} className='award-image' alt='award-preview-image'></img>
                                  </div>
                                  <div className='modal-detail-section  w-100 d-flex justify-content-between'>
                                      <div className='awards-details me-2'>
                                          <a
                                              href={res.page_url}
                                              className={`${'text-decoration-none award-title text-black mb-1 cursor-pointer '}${
                                                  res.page_url == '#' ? 'not-clickable' : ''
                                              }`}
                                          >
                                              {res.title}
                                          </a>
                                          <p className='m-0 awarded text-black mb-1'>
                                              {t('connect.awarded')} {res.created_at}
                                          </p>
                                          <p className='m-0 award-text text-black'>{res.text}</p>
                                      </div>
                                      <div className='award-badge-image-section h-100 d-flex align-items-center'>
                                          <img src={res.badge_image ?? res.preview_image} className='award-badge-image' alt='award-badge-image' />
                                      </div>
                                  </div>
                              </div>
                          </li>
                      ))
                    : null}
            </ul>
        );
    };

    const isTabActive = (tabID: number) => {
        return tabActive === tabID ? 'text-secondary' : 'text-primary';
    };

    const updateFavourite = (event: any) => {
        let formData = new FormData();
        let favourite = !event.target.classList.contains('fa-thin');
        formData.append('is_favorite', favourite ? '0' : '1');
        if (favourite) {
            setAddFav(contactUserId);
            setIsFav(false);
            setRemoveFav('');
        } else {
            setAddFav('');
            setIsFav(true);
            setRemoveFav(contactUserId);
        }
        connectService.updateFavouritesList(contactUserId, formData, () => {
            fetchDataById(contactUserId);
        });
    };

    const updateLists = (event: any) => {
        if (event.target.classList.contains('fa-thin')) {
            setAddFav(contactUserId);
            setIsFav(false);
            setRemoveFav('');
        } else {
            setAddFav('');
            setIsFav(true);
            setRemoveFav(contactUserId);
        }
        fetchDataById(contactUserId);
    };

    const resetFavStates = () => {
        setAddFav('');
        setRemoveFav('');
    };

    const setFavState = (id: string, status: boolean) => {
        if (id === contactUserId) setIsFav(status);
    };

    const renderOption = (res: DynamicAttributesData) => {
        switch (res.attribute_type) {
            case 'Phone':
                return (
                    <a className='text-decoration-none text-secondary cursor-pointer' href={'tel:' + res.value}>
                        {res.value}
                    </a>
                );
            case 'Email':
                return (
                    <a className='text-decoration-none text-secondary cursor-pointer' href={'mailto:' + res.value}>
                        {res.value}
                    </a>
                );
            case 'Url':
                return (
                    <a
                        target='_blank'
                        className='text-decoration-none text-secondary cursor-pointer'
                        href={res.value.includes('http://') || res.value.includes('https://') ? res.value : 'https://' + res.value}
                    >
                        {res.value}
                    </a>
                );
            case 'Multi-Select':
                if (Array.isArray(res.value) && res.value.length > 0) {
                    return res.value.join(', ');
                }
                return res.value;
            default:
                return res.value;
        }
    };

    const isLoadingCallBack = () => {
        setIsLoading(true);
    };

    const handleFollowingCount = () => {
        fetchDataById(id ? id : contactUserId);
    };

    const isLoggedInUser = (id: number) => {
        return id === loggedInUserContactId ? true : false;
    };

    const handleSelectedUserDetails = () => {
        localStorage.setItem('to_id', userDetail ? userDetail.id.toString() : '');
        localStorage.setItem('to_name', userDetail ? userDetail.full_name.toString() : '');
        localStorage.setItem('to_manager', userDetail ? userDetail.has_manager.toString() : '');
        localStorage.setItem('to_image', userDetail ? userDetail.image_url.toString() : '');
    };

    const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
    const bgColor = isHeaderWhite ? tenantBranding.web_navbar_foreground : tenantBranding.web_navbar_background;
    const fgColor = isHeaderWhite ? tenantBranding.web_navbar_background : tenantBranding.web_navbar_foreground;

    const handleScroll = (e: any) => {
        if (tabActive === 2 && showResults) {
            const isAtBottom = Math.round(e.target.clientHeight + e.target.scrollTop) >= e.target.scrollHeight;
            setScrollIsBottom(isAtBottom);
        }
    };

    const getHeaderBackgroundImage = () => {
        const imageUrl = userDetail?.header_image_url;
        return imageUrl ? `url(${imageUrl})` : '';
    };

    const getHeaderBackgroundColour = () => {
        const imageUrl = userDetail?.header_image_url;
        const backgroundColour = p2pBtn.background ? p2pBtn.background : '#62257d';
        return !imageUrl ? backgroundColour : '';
    };

    return (
        <div data-testid='contact-details-section' className='contact-details-section'>
            <AnalyticsComponent activityId={activityId} />
            <Row className='contact-row'>
                {!hideContactList && (
                    <Col lg={4} md={12}>
                        <ConnectPage
                            addFavorite={addFav}
                            removeFavorite={removeFav}
                            clickCallback={resetFavStates}
                            updateFavCallback={setFavState}
                            setPostTabDetail={setPostTabDetail}
                            contactUserId={contactUserId}
                            loggedInUserLocationId={loggedInUserLocationId}
                            selectedUserLocationId={mainDetail && mainDetail.location_contact_id ? mainDetail.location_contact_id.toString() : ''}
                        />
                    </Col>
                )}
                <Col lg={!hideContactList ? 8 : 12} md={12} className='contact-user-detail-section'>
                    {isOpenChart && orgChartData ? (
                        <OrgChartModalComponent
                            isOpenChart={isOpenChart}
                            renderModalData={orgChartData}
                            headerName={headerName}
                            setIsOpenChart={setIsOpenChart}
                        />
                    ) : (
                        ''
                    )}
                    <>
                        {isAwardOpen ? (
                            <AwardsModalComponent
                                username={userDetail?.full_name}
                                isOpen={isAwardOpen}
                                awards={userDetail?.awards}
                                setIsOpen={setIsAwardOpen}
                                renderModalData={renderModalData}
                            />
                        ) : (
                            ''
                        )}
                        <div
                            className='right-side panel-heading profile-container details'
                            style={{
                                height: hideContactList ? '75vh' : '86vh',
                            }}
                        >
                            {isLoading ? (
                                <div className='d-flex align-items-center justify-content-center h-100'>
                                    <Spinner />
                                </div>
                            ) : editMode && editUserData ? (
                                <ContactEditProfilePage editUserData={editUserData} isLoadingCallBack={isLoadingCallBack} />
                            ) : (
                                <div className='profile' onScroll={handleScroll}>
                                    {hideContactList && (
                                        <button
                                            className='unstyled-btn'
                                            style={{
                                                float: 'right',
                                                cursor: 'pointer',
                                                margin: '10px',
                                                position: 'sticky',
                                                zIndex: 1,
                                            }}
                                            data-testid='close-contact-details'
                                            onClick={() => {
                                                search.includes('starred') ? navigate('/connect/people?starred') : navigate('/connect/people');
                                            }}
                                        >
                                            <span className='fa-stack' style={{ fontSize: '18px' }}>
                                                <i className='fa-solid fa-circle fa-stack-2x' style={{ color: '#f3f3f3', fontStyle: 'normal' }}></i>
                                                <i
                                                    className='fa-regular fa-xmark fa-stack-1x fa-inverse'
                                                    style={{ color: '#000', fontStyle: 'normal' }}
                                                ></i>
                                            </span>
                                        </button>
                                    )}
                                    <div
                                        className={`position-relative thumb128 ml-auto mr-auto contact-header`}
                                        style={{
                                            backgroundSize: 'cover',
                                            backgroundColor: `${getHeaderBackgroundColour()}`,
                                            backgroundImage: `${getHeaderBackgroundImage()}`,
                                        }}
                                        data-testid='profile-cover-image'
                                    >
                                        <div>
                                            <img
                                                src={userDetail?.image_url ? userDetail?.image_url : 'https://cdn.engageesp.com/img/person.png'}
                                                className='rounded-circle mb-lg image-placement'
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={`ms235 d-flex align-items-center justify-content-between username-section ${
                                            userDetail?.id != loggedInUserId ? 'username-section-column' : ''
                                        }`}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <h1 className='mb-0 user-full-name'>{userDetail?.full_name ? userDetail?.full_name : ''}</h1>
                                            {userDetail?.id != loggedInUserId ? (
                                                <i
                                                    className={
                                                        isFav
                                                            ? 'fa-solid fa-star user-fav-icon fav ms-2 cursor-pointer'
                                                            : 'fa-thin fa-star user-fav-icon fav ms-2 ml-sm cursor-pointer'
                                                    }
                                                    data-id='7909'
                                                    data-self='true'
                                                    data-testid='fav-button'
                                                    onClick={hideContactList ? updateFavourite : updateLists}
                                                    style={{
                                                        color: bgColor,
                                                    }}
                                                />
                                            ) : null}
                                        </div>

                                        {userDetail?.id == loggedInUserId ? (
                                            <Link
                                                to={`/connect/people/${userDetail?.contact_id}/edit`}
                                                className='profile-edit cursor-pointer d-flex align-items-center justify-content-center'
                                                style={{
                                                    backgroundColor: bgColor,
                                                    color: fgColor,
                                                }}
                                            >
                                                <span className='me-2 profile-edit-text'>{t('connect.edit')}</span>
                                                <FontAwesomeIcon style={{ color: fgColor }} icon={faPenCircle} className='user-edit-icon' />
                                            </Link>
                                        ) : (
                                            <div className='d-flex align-items-center contact-user-social-button-section'>
                                                <button
                                                    className={`btn btn-default font-weight-semibold user-follow-button justify-content-center align-items-center w-20 follow-user ${
                                                        mainDetail?.followed || !userInfo.spEnabled ? 'd-none' : ''
                                                    }`}
                                                    type='button'
                                                    data-testid='follow-button'
                                                    onClick={() => followRequest(userDetail?.id!)}
                                                    style={{
                                                        backgroundColor: bgColor,
                                                        borderColor: bgColor,
                                                        color: fgColor,
                                                    }}
                                                >
                                                    {t('connect.follow')}
                                                </button>

                                                <div
                                                    className={`following-button d-inline  ${
                                                        mainDetail?.followed && userInfo.spEnabled ? '' : 'd-none'
                                                    }`}
                                                >
                                                    <button
                                                        type='button'
                                                        className='btn d-flex user-following-button justify-content-center
                                                    align-items-center w-20'
                                                        id='followingMenu'
                                                        onClick={toggleAliasHandleOnClick}
                                                        data-mdb-toggle='dropdown'
                                                        aria-expanded='false'
                                                        style={{
                                                            backgroundColor: bgColor,
                                                            borderColor: bgColor,
                                                            color: fgColor,
                                                        }}
                                                    >
                                                        <span className='me-1 font-weight-semibold'>{t('connect.following')}</span>
                                                        <Dropdown
                                                            toggle={toggleAliasHandleOnClick}
                                                            isOpen={toggleAlias}
                                                            className='d-inline ms-1'
                                                            data-testid='dropdown-options'
                                                        >
                                                            <DropdownToggle
                                                                data-testid='dropdown-toggle'
                                                                className='btn dropdown-toggle-button'
                                                                style={{
                                                                    color: fgColor,
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faAngleDown} />
                                                            </DropdownToggle>
                                                            <DropdownMenu right direction='down' className='following-dropdown-menu'>
                                                                <DropdownItem
                                                                    data-testid='unfollow-btn'
                                                                    className='unfollow-btn'
                                                                    onClick={() => unfollowRequest(userDetail?.id.toString()!)}
                                                                >
                                                                    {t('connect.unfollow')}
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    className={mainDetail?.snoozed ? '' : 'd-none'}
                                                                    onClick={() => snoozeUntil(userDetail?.id.toString()!, '0')}
                                                                >
                                                                    {t('connect.unsnooze')}
                                                                </DropdownItem>
                                                                <Dropdown
                                                                    toggle={toggleSubAliasHandleOnClick}
                                                                    isOpen={toggleSubAlias}
                                                                    data-testid='snooze-options'
                                                                    className={`snooze-menu ${mainDetail?.snoozed ? 'd-none' : ''}`}
                                                                >
                                                                    <DropdownToggle
                                                                        caret
                                                                        className='dropdown-item d-flex align-items-center justify-content-center'
                                                                        onClick={(e) => e.stopPropagation()}
                                                                    >
                                                                        {t('connect.snooze')}
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem
                                                                            data-testid='snooze-option'
                                                                            onClick={() => snoozeUntil(userDetail?.id.toString()!, '10080')}
                                                                        >
                                                                            {t('connect.1Week')}
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                            data-testid='snooze-option'
                                                                            onClick={() => snoozeUntil(userDetail?.id.toString()!, '43800')}
                                                                        >
                                                                            {t('connect.1Month')}
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                            data-testid='snooze-option'
                                                                            onClick={() => snoozeUntil(userDetail?.id.toString()!, '525600')}
                                                                        >
                                                                            {t('connect.1Year')}
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </button>
                                                </div>
                                                {tenantInfo && tenantInfo.messagesEnabled ? (
                                                    <button
                                                        className='btn font-weight-semibold user-message-button justify-content-center align-items-center mr-25 w-20 message-user'
                                                        type='button'
                                                        data-testid='message-btn'
                                                        onClick={() => sendMessage(userDetail?.id)}
                                                        style={{
                                                            backgroundColor: bgColor,
                                                            borderColor: bgColor,
                                                            color: fgColor,
                                                        }}
                                                    >
                                                        {t('connect.message')}
                                                    </button>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                    <div className='d-flex contact-user-info-section'>
                                        <div className='user-detail'>
                                            {userDetail && userDetail.is_visible_department && userDetail.department && userDetail.department.length > 0 && (
                                                <div className='mb-1 ms235'>
                                                    <strong className='sub-header-text'>{userDetail.department}</strong>
                                                </div>
                                            )}
                                            {userDetail && userDetail.is_visible_title && userDetail.job_title && userDetail.job_title.length > 0 && (
                                                <div className='mb-1 sub-header-text ms235'>{userDetail.job_title}</div>
                                            )}
                                            <div className={`d-flex me-1 ms235 ${(!userDetail?.department || !userDetail.is_visible_department) && (!userDetail?.job_title || !userDetail.is_visible_title) ? 'mt-2' : ''}`}>
                                                <span
                                                    className='mt-1 following-count'
                                                    onClick={() => {
                                                        setOpenFollowingDetailDialog({
                                                            open: true,
                                                            defaultTab: 0,
                                                        });
                                                    }}
                                                >
                                                    {mainDetail?.following ? mainDetail.following : '0'} {t('connect.following')}
                                                </span>
                                                <span
                                                    className='mt-1 ml-3 followers-count'
                                                    data-total='0'
                                                    onClick={() => {
                                                        setOpenFollowingDetailDialog({
                                                            open: true,
                                                            defaultTab: 1,
                                                        });
                                                    }}
                                                >
                                                    {mainDetail?.followers ? mainDetail.followers : '0'} {t('connect.followers')}
                                                </span>
                                            </div>
                                        </div>
                                        {tenantInfo.allowP2P && userDetail?.id != loggedInUserId && (
                                            <div className='d-flex justify-content-end w-100 contact-thank-you-section'>
                                                <SendThankYouButton
                                                    className='contact-send-thank-you-button'
                                                    label={awardsConfigName}
                                                    backgroundColor={p2pBtn.background}
                                                    foregroundColor={p2pBtn.foreground}
                                                    icon={p2pBtn.icon}
                                                    handleOnClick={handleSelectedUserDetails}
                                                    isIconAtEnd={true}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {openFollowingDetailDialog.open && (
                                        <FollowingDetailModal
                                            isOpen={openFollowingDetailDialog.open}
                                            userId={id ? parseInt(id) : parseInt(contactUserId)}
                                            defaultTab={openFollowingDetailDialog.defaultTab}
                                            totalFollowers={mainDetail ? mainDetail.followers : 0}
                                            totalFollowing={mainDetail ? mainDetail.following : 0}
                                            isLoggedInUser={isLoggedInUser(id ? parseInt(id) : parseInt(contactUserId))}
                                            handleDialogClose={() => {
                                                handleFollowingCount();
                                                setOpenFollowingDetailDialog({ open: false, defaultTab: 0 });
                                            }}
                                        />
                                    )}
                                    <div className='d-block awards-container pt-sm ms235'>
                                        <div className='user-awards d-flex align-items-center mb-20'>
                                            {userDetail?.awards && userDetail?.awards.length > 0
                                                ? userDetail?.awards.slice(0, 7).map((award, index) => {
                                                      return (
                                                          <div
                                                              key={award.id}
                                                              className={`d-inline-block ${index == 0 ? '' : 'ms-1'} me-1 award-item mt-0`}
                                                          >
                                                              <ReactTooltip anchorId={`detail_${award?.id}`} />
                                                              <img
                                                                  src={award.badge_image ?? award.preview_image}
                                                                  className='thumb32 mt-1 mb-2 cursor-pointer'
                                                                  onClick={modalAwardTrigger}
                                                                  id={`detail_${award?.id}`}
                                                                  data-tooltip-content={award.title}
                                                                  data-iscapture='true'
                                                              />
                                                          </div>
                                                      );
                                                  })
                                                : null}

                                            {userDetail?.awards && userDetail?.awards.length > 6 && (
                                                <>
                                                    <ReactTooltip anchorId={`user-remaining-awards`} />
                                                    <button
                                                        id='user-remaining-awards'
                                                        className='d-flex align-items-center justify-content-center contact-remaining-awards'
                                                        onClick={modalAwardTrigger}
                                                        style={{
                                                            backgroundColor: bgColor,
                                                            color: fgColor,
                                                        }}
                                                        data-tooltip-content={`+${userDetail?.awards.length - 6} ${t('awards.awards')}`}
                                                        data-iscapture='true'
                                                    >
                                                        <span>+</span>
                                                        <span className='contact-awards-count'>{userDetail?.awards.length - 6}</span>
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className='w-100 d-flex justify-content-center buttons-menu'>
                                        <div className='contact-details-tab me-3'>
                                            <div
                                                className={`${
                                                    mainDetail?.social_post_view == false && !tenantInfo.allowP2P ? '' : 'float-right'
                                                } h-100 d-flex align-items-center mr-0 cursor-pointer show-details`}
                                                onClick={onClickDetails}
                                                style={{
                                                    borderBottom: tabActive == 1 ? `5px solid ${bgColor}` : '',
                                                    paddingBottom: tabActive != 1 ? '11px' : '6px',
                                                }}
                                            >
                                                <FontAwesomeIcon className='me-2 details-tab-icon text-black' icon={faBookOpenReader} />
                                                <h3 className='m-0'>
                                                    <strong className='details-tab h-100 d-flex align-items-center text-black'>
                                                        {t('connect.details')}
                                                    </strong>
                                                </h3>
                                            </div>
                                        </div>

                                        {mainDetail?.social_post_view == true ? (
                                            <div className='contact-posts-tab me-3'>
                                                <div
                                                    data-reactId={1}
                                                    className={`d-flex h-100 align-items-center cursor-pointer show-posts float-left`}
                                                    onClick={onClick}
                                                    style={{
                                                        borderBottom: tabActive == 2 ? `5px solid ${bgColor}` : '',
                                                        paddingBottom: tabActive != 2 ? '11px' : '6px',
                                                    }}
                                                >
                                                    <svg
                                                        className='me-2'
                                                        width='22'
                                                        height='22'
                                                        viewBox='0 0 19 17'
                                                        fill='none'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                    >
                                                        <path
                                                            d='M17.2188 0H5.34375C4.36035 0 3.5625 0.816987 3.5625 1.82143V14.5714C3.5625 15.2416 3.02961 15.7857 2.375 15.7857C1.72039 15.7857 1.1875 15.2416 1.1875 14.5714V3.03571C1.1875 2.70179 0.921797 2.42857 0.59375 2.42857C0.265703 2.42857 0 2.70179 0 3.03571V14.5714C0 15.9102 1.06504 17 2.375 17H16.0312C17.6681 17 19 15.6385 19 13.9643V1.82143C19 0.816987 18.2021 0 17.2188 0ZM17.8125 13.9643C17.8125 14.9687 17.0135 15.7857 16.0312 15.7857H4.43086C4.63496 15.429 4.75 15.0154 4.75 14.5714V1.82143C4.75 1.48712 5.01719 1.21429 5.34375 1.21429H17.2188C17.5453 1.21429 17.8125 1.48712 17.8125 1.82143V13.9643ZM10.0938 10.3214H6.53125C6.20469 10.3214 5.9375 10.5946 5.9375 10.9286C5.9375 11.2625 6.20305 11.5357 6.53125 11.5357H10.0938C10.4219 11.5357 10.6875 11.2642 10.6875 10.9286C10.6875 10.593 10.4203 10.3214 10.0938 10.3214ZM16.0312 10.3214H12.4688C12.1422 10.3214 11.875 10.5946 11.875 10.9286C11.875 11.2625 12.1406 11.5357 12.4688 11.5357H16.0312C16.3594 11.5357 16.625 11.2642 16.625 10.9286C16.625 10.593 16.3578 10.3214 16.0312 10.3214ZM10.0938 12.75H6.53125C6.20469 12.75 5.9375 13.0232 5.9375 13.3571C5.9375 13.6911 6.20305 13.9643 6.53125 13.9643H10.0938C10.4219 13.9643 10.6875 13.6927 10.6875 13.3571C10.6875 13.0215 10.4203 12.75 10.0938 12.75ZM16.0312 12.75H12.4688C12.1406 12.75 11.875 13.0215 11.875 13.3571C11.875 13.6927 12.1406 13.9643 12.4688 13.9643H16.0312C16.3594 13.9643 16.625 13.6927 16.625 13.3571C16.625 13.0215 16.3578 12.75 16.0312 12.75ZM15.4375 2.42857H7.125C6.46816 2.42857 5.9375 2.97121 5.9375 3.64286V7.28571C5.9375 7.95623 6.46928 8.5 7.125 8.5H15.4375C16.0932 8.5 16.625 7.95623 16.625 7.28571V3.64286C16.625 2.97121 16.0943 2.42857 15.4375 2.42857ZM15.4375 7.28571H7.125V3.64286H15.4375V7.28571Z'
                                                            fill='black'
                                                        />
                                                    </svg>
                                                    <h3 className='h-100 text-black m-0 d-flex align-items-center'>
                                                        <strong className='h-100 d-flex align-items-center text-black posts-tab'>
                                                            {t('connect.posts')}
                                                        </strong>
                                                    </h3>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {mainDetail?.manager && mainDetail?.manager.full_name && (
                                            <div className='contact-organization-tab'>
                                                <button
                                                    className='h-100 chart-button d-flex align-items-center'
                                                    aria-label='chart button'
                                                    onClick={onClickOrganisation}
                                                    style={{
                                                        borderBottom: tabActive == 3 ? `5px solid ${bgColor}` : '',
                                                        paddingBottom: tabActive != 3 ? '11px' : '6px',
                                                    }}
                                                >
                                                    <FontAwesomeIcon className='text-black me-2 organization-tab-icon' icon={faSitemap} />
                                                    <h3 className='text-black h-100 m-0 d-flex align-items-center'>
                                                        <strong className='text-black organization-text'>{t('connect.organisation')}</strong>
                                                    </h3>
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    {contactDetailsData ? (
                                        <div className='card-body px-4 pt-0'>
                                            <div className='ml-20'>
                                                {mainDetail?.manager && mainDetail?.manager.full_name ? (
                                                    <IconCard icon={mainDetail?.manager.image_url} className='details-card align-items-center'>
                                                        <Box className='details-card-description'>
                                                            <Typography>{t('connect.manager')}</Typography>
                                                            <Typography onClick={onClickOrganisation} className='cursor-pointer'>
                                                                {mainDetail?.manager.full_name}
                                                            </Typography>
                                                        </Box>
                                                    </IconCard>
                                                ) : null}
                                                {mainDetail?.location ? (
                                                    <IconCard
                                                        className='details-card'
                                                        icon={<i className='fal fa-map-marker-alt details-card-icon' />}
                                                    >
                                                        <Box className='details-card-description'>
                                                            <Typography>{t('connect.location')}</Typography>
                                                            <Typography>{mainDetail?.location}</Typography>
                                                        </Box>
                                                    </IconCard>
                                                ) : null}
                                                {mainDetail?.email ? (
                                                    <IconCard
                                                        className='details-card'
                                                        icon={<i className='fal fa-envelope-open-text details-card-icon' />}
                                                    >
                                                        <Box className='details-card-description'>
                                                            <Typography>{t('connect.primaryEmail')}</Typography>
                                                            <Typography>
                                                                <a
                                                                    className='text-decoration-none cursor-pointer'
                                                                    href={'mailto:' + mainDetail?.email}
                                                                >
                                                                    {mainDetail?.email}
                                                                </a>
                                                            </Typography>
                                                        </Box>
                                                    </IconCard>
                                                ) : null}
                                                {mainDetail?.date_of_birth ? (
                                                    <IconCard
                                                        className='details-card'
                                                        icon={<i className='fal fa-light fa-calendar-star details-card-icon' />}
                                                    >
                                                        <Box className='details-card-description'>
                                                            <Typography>{t('connect.dob')}</Typography>
                                                            <Typography>{mainDetail?.date_of_birth}</Typography>
                                                        </Box>
                                                    </IconCard>
                                                ) : null}
                                                {mainDetail?.start_date ? (
                                                    <IconCard className='details-card' icon={<i className='fal fa-calendar-alt details-card-icon' />}>
                                                        <Box className='details-card-description'>
                                                            <Typography>{t('connect.start')}</Typography>
                                                            <Typography>{mainDetail?.start_date}</Typography>
                                                        </Box>
                                                    </IconCard>
                                                ) : null}
                                                {mainDetail?.bio ? (
                                                    <IconCard className='details-card' icon={<i className='fal fa-circle-info details-card-icon' />}>
                                                        <Box className='details-card-description'>
                                                            <Typography>{t('connect.bio')}</Typography>
                                                            <Typography>{mainDetail?.bio}</Typography>
                                                        </Box>
                                                    </IconCard>
                                                ) : null}
                                                {contactAttrDetails && Object.keys(contactAttrDetails).length > 0
                                                    && Object.keys(contactAttrDetails).map((key: any) => {
                                                        let res = contactAttrDetails[key];
                                                          return (<IconCard
                                                              className='details-card'
                                                              icon={<i className={`${res.icon_class} details-card-icon`} />}
                                                              key={res.id}
                                                          >
                                                              <Box className='details-card-description'>
                                                                  <Typography>{res?.title}</Typography>
                                                                  <Typography>{renderOption(res)}</Typography>
                                                              </Box>
                                                          </IconCard>
                                                      )})}
                                            </div>
                                        </div>
                                    ) : null}
                                    <div
                                        className={`card-body px-0 ${showResults ? '' : ' d-none'}`}
                                        style={{
                                            backgroundColor: postTabDetail.postData.length > 0 ? '#F3F3F3' : '#fff',
                                        }}
                                    >
                                        <div className='ml-20 pb-1'>
                                            {showResults && (
                                                <Posts
                                                    selectedInUserId={selectedInUserId}
                                                    scrollIsBottom={scrollIsBottom}
                                                    postTabDetail={postTabDetail}
                                                    setPostTabDetail={setPostTabDetail}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {showOrgChart && (
                                        <div data-testid='orgchart-view'>
                                            {orgChartData ? (
                                                <OrgChart headerName={headerName} renderModalData={orgChartData} />
                                            ) : (
                                                <div className='alert alert-primary message-empty-posts  text-center w-50 m-auto mt-20' role='alert'>
                                                    {t('connect.noOrgChart')}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </>
                </Col>
            </Row>
        </div>
    );
}

export default ContactDetailsPage;
