export default {
  "please enter your company reference": "Inserisci il riferimento della tua azienda",
  "enter your company reference": "Inserisci il riferimento della tua azienda",
  "please enter your user ID and password": "Inserisci il tuo ID utente e la password",
  "please enter user id": "Inserisci il tuo ID utente",
  "enter password": "Inserisci la tua password",
  "forgot password": "Password dimenticata",
  "errors": {
    "domain name can't be empty": "Il nome di dominio non può essere vuoto",
    "user name can't be empty": "Il nome utente non può essere vuoto",
    "password can't be empty": "La password non può essere vuota",
    "incorrect user id or password": "ID utente o password errati",
    "domain does not exist": "Il dominio '{{domain}}' non esiste",
    "account locked": "Ci sono stati troppi tentativi, l'account ora è bloccato"
  },
  "hello": "Ciao",
  "log in": "Effettua il login",
  "new user": "Nuovo utente",
  "create new account": "Crea un nuovo account",
  "next": "Prossimo",
  "verify": "VERIFICARE",
  "contact support": "Contatta l'assistenza",
  "back": "INDIETRO"
};