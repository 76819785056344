import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LocationsPage from './locationsPage';
import { Row, Col } from 'reactstrap';
import { AxiosResponse } from 'axios';
import './../contactPage/contactPage.scss';
import { useTranslation } from 'react-i18next';
import locationsService from '../../../services/locationsService';
import { DynamicAttributesData, LocationDetailsData } from '../../../../types/types';
import Spinner from '../../common/spinnerLoad/spinnerLoad';
import AnalyticsComponent from '../../common/analytics/analyticsComponent';
import { IconCard } from '../../IconCard/iconCard';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { isWhite } from '../../../utils/colourCheck';

type locationParams = {
    id: string;
};

function LocationsDetailsPage(props: { displayLocationList?: boolean }) {
    let { displayLocationList = true } = props;
    let { id } = useParams<locationParams>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [locationDetails, setLocationDetails] = useState<LocationDetailsData>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [activityId, setActivityId] = useState<string>('');
    const tenantBranding = useSelector((state: any) => state.app.branding);

    useEffect(() => {
        getDetailsData();
    }, [id]);

    const getDetailsData = () => {
        setIsLoading(true);
        locationsService.getLocationDetails(id || '', (resp: AxiosResponse) => {
            if (id) {
                setLocationDetails(resp.data.data);
                setActivityId(resp.data.data.activity_id);
            } else if (resp.data.data.length > 0) {
                navigate(`/location/${resp.data.data[0].id}`);
            }
            setIsLoading(false);
        });
    };

    const renderOption = (res: DynamicAttributesData) => {
        if (res.attribute_type === 'Phone') {
            return (
                <a className='text-decoration-none text-secondary cursor-pointer' href={'tel:' + res.value}>
                    {res.value}
                </a>
            );
        }
        if (res.attribute_type === 'Email') {
            return (
                <a className='text-decoration-none text-secondary cursor-pointer' href={'mailto:' + res.value}>
                    {res.value}
                </a>
            );
        }
        if (res.attribute_type === 'Url') {
            return (
                <a
                    target='_blank'
                    className='text-decoration-none text-secondary cursor-pointer'
                    href={res.value.includes('http://') || res.value.includes('https://') ? res.value : 'https://' + res.value}
                >
                    {res.value}
                </a>
            );
        } else {
            return res.value;
        }
    };

    const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
    const bgColor = isHeaderWhite ? tenantBranding.web_navbar_foreground : tenantBranding.web_navbar_background;
    const fgColor = isHeaderWhite ? tenantBranding.web_navbar_background : tenantBranding.web_navbar_foreground;

    return (
        <div data-testid='location-details-section'>
            <AnalyticsComponent activityId={activityId} />
            <Row>
                {displayLocationList && (
                    <Col lg={4} sm={6}>
                        <LocationsPage selectedLocationId={id || ''} />
                    </Col>
                )}
                <Col lg={displayLocationList ? 8 : 12} sm={displayLocationList ? 8 : 12}>
                    <div
                        className='right-side panel-heading cls-location-details profile-container details'
                        style={{
                            height: !displayLocationList ? '75vh' : '86vh',
                            backgroundColor: 'white',
                        }}
                    >
                        {isLoading ? (
                            <div className='d-flex align-items-center justify-content-center h-100'>
                                <Spinner />
                            </div>
                        ) : (
                            <div>
                                {!displayLocationList && (
                                    <button
                                        className='unstyled-btn'
                                        style={{
                                            float: 'right',
                                            cursor: 'pointer',
                                            margin: '5px',
                                            position: 'sticky',
                                            zIndex: 1000,
                                        }}
                                        data-testid='close-contact-details'
                                        onClick={() => {
                                            //remove the id from the current url
                                            navigate('/connect/locations');
                                        }}
                                    >
                                        <span className='fa-stack' style={{ fontSize: '18px' }}>
                                            <i className='fa-solid fa-circle fa-stack-2x' style={{ color: '#f3f3f3', fontStyle: 'normal' }}></i>
                                            <i
                                                className='fa-regular fa-xmark fa-stack-1x fa-inverse'
                                                style={{ color: '#000', fontStyle: 'normal' }}
                                            ></i>
                                        </span>
                                    </button>
                                )}
                                <div
                                    className='location-title'
                                    style={{
                                        backgroundColor: bgColor,
                                        color: fgColor,
                                    }}
                                >
                                    {locationDetails ? locationDetails.title : ''}
                                </div>
                                <div className='profile'>
                                    <div className='position-relative thumb128 ml-auto mr-auto border-secondary'>
                                        <iframe
                                            title='location frame'
                                            src={`https://maps.google.com/maps?q=${locationDetails ? locationDetails.latitude : ''},${
                                                locationDetails ? locationDetails.longitude : ''
                                            }&z=15&output=embed`}
                                            width='100%'
                                            height={displayLocationList ? '350px' : '250px'}
                                            className='w-100 m0 p0'
                                        ></iframe>
                                    </div>
                                    <div className='d-block awards-container pt-sm'>
                                        <div className='custom-spinner awards-spinner d-none'></div>
                                        <div className='user-awards cursor-pointer d-inline-block'></div>
                                    </div>
                                    <div className='d-inline mt-10'>
                                        <div className='following-button d-inline d-none'></div>
                                    </div>
                                    <div className='row mt-15'>
                                        <div className='col-lg-5'>
                                            <div className='d-grid mr-0 cursor-pointer show-details text-primary float-right'></div>
                                        </div>
                                        <div className='col-lg-2'>
                                            <div className='connect-icons-border border-color-secondary d-grid'>
                                                <div className='disable-tyc'></div>
                                            </div>
                                        </div>

                                        <div className='col-lg-5'>
                                            <div className='d-grid cursor-pointer show-posts text-secondary float-left'></div>
                                        </div>
                                    </div>
                                    <div className='card-body px-4 pt-0'>
                                        <div className='details-container ml-20'>
                                            {locationDetails && locationDetails.address && (
                                                <IconCard className='details-card' icon={<i className='fal fa-map-marker-alt details-card-icon' />}>
                                                    <Box className='details-card-description'>
                                                        <Typography>{t('connect.address')}</Typography>
                                                        <Typography>{locationDetails?.address}</Typography>
                                                    </Box>
                                                </IconCard>
                                            )}
                                            {locationDetails && locationDetails.description && (
                                                <IconCard className='details-card' icon={<i className='fal fa-circle-info details-card-icon' />}>
                                                    <Box className='details-card-description'>
                                                        <Typography>{t('connect.description')}</Typography>
                                                        <Typography>{locationDetails.description}</Typography>
                                                    </Box>
                                                </IconCard>
                                            )}
                                            {locationDetails && locationDetails.dynamic_attributes?.length > 0 ? (
                                                locationDetails.dynamic_attributes.map((res: DynamicAttributesData) => (
                                                    <IconCard
                                                        className='details-card'
                                                        icon={<i className={`${res.icon_class} details-card-icon`} />}
                                                        key={res.id}
                                                    >
                                                        <Box className='details-card-description'>
                                                            <Typography>{res?.title}</Typography>
                                                            <Typography>{renderOption(res)}</Typography>
                                                        </Box>
                                                    </IconCard>
                                                ))
                                            ) : locationDetails && locationDetails.address ? (
                                                ''
                                            ) : (
                                                <p>{t('connect.noDataFound')}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default LocationsDetailsPage;
