import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Card } from 'reactstrap';
import { useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { FeedSocialPost } from '../../../types/types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import FeedService from '../../services/feedService';
import ThreadService from '../../services/threadService';
import objectAssign from 'object-assign';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';

import Scrollbar from '../common/scrollbar/scrollbar';
import SpinnerLoad from '../common/spinnerLoad/spinnerLoad';
import PagesItemType from './itemTypes/pages/pagesItem';
import PNSItemType from './itemTypes/pnsItem';
import TYCardsItemType from './itemTypes/tyCardsItem';
import SocialPostItemType from './itemTypes/socialPost/SocialPostItem';
import UsersListModal from '../common/usersList/usersListModal';
import CreateSocialPost from './createSocialPost';
import RecentComments from '../customLayout/recentComments';
import './feedPage.scss';
import ProfileSection from './profileSection/profileSection';
import { LayoutContainer } from '../../../types/types';
import { Box } from '@mui/material';
import feedService from '../../services/feedService';
import CustomLayout from '../customLayout/customLayout';
import InfiniteScroll from 'react-infinite-scroll-component';

export type FeedPageProps = {
    customLayoutFeed?: boolean;
    fixedContainer?: boolean;
};

const FeedPage = ({ customLayoutFeed, fixedContainer }: FeedPageProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tenantInfo = useSelector((state: any) => state.app.tenant);
    const userInfo = useSelector((state: any) => state.app.user);

    const [feedData, setFeedData] = useState<Array<any>>([]);
    const [commentsData, setComments] = useState<Array<any>>([]);
    const [pageLoading, setPageLoading] = useState(false);
    const [commentsLoading, setCommentsLoading] = useState(true);
    const [pageToShare, setSharePage] = useState<any>(null);

    const [page, setPage] = useState(1);
    const [userData, setUserData] = useState<any>({});
    const [hasMoreData, setHasMoreData] = useState(true);
    const [layoutLoading, setLayoutLoading] = useState<boolean>(false);
    const [templateId, setTemplateId] = useState<number>(-1);
    const [layoutContainers, setLayoutContainers] = useState<LayoutContainer[]>([]);
    const [config, setConfig] = useState<any>();

    useEffect(() => {
        const fetchData = () => {
            setLayoutLoading(true);
            feedService.getLayout((response: AxiosResponse) => {
                if (response.data.layout == 'custom') {
                    setLayoutContainers(response.data.layout_data[0].data.containers);
                    setTemplateId(response.data.layout_data[0].data.templateId);
                }
                setConfig(response.data.layout);
                setLayoutLoading(false);
            });
        };
        fetchData();
    }, []);

    const loadMoreContentPages = () => {
        setPage((page) => page + 1);
        setPageLoading(true);
        FeedService.getData(
            page,
            (resp: AxiosResponse) => {
                appendFeedData(resp.data.data);
                setPageLoading(false);
                setHasMoreData(page < resp.data.meta.last_page);
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Feed Data`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
                setPageLoading(false);
            }
        );
    };

    useEffect(() => {
        FeedService.getLastComments(
            (resp: AxiosResponse) => {
                setComments(resp.data.data);
                setCommentsLoading(false);
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Last Comments`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
                setCommentsLoading(false);
            }
        );
        loadMoreContentPages();
    }, []);

    const pageScroll = (e: any) => {
        if (!pageLoading && hasMoreData && Math.round(e.scrollTop + e.clientHeight) >= e.scrollHeight) {
            loadMoreContentPages();
        }
    };

    const customPageScroll = (e: any) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        if (!pageLoading && hasMoreData && Math.round(scrollTop + clientHeight) >= scrollHeight) {
            loadMoreContentPages();
        }
    };

    const updatePost = (post: FeedSocialPost) => {
        const newFeedData = [...feedData];
        const item = newFeedData.find((p) => (p.type === 'post' || p.type === 'card-p2p') && p.model.id === post.model.id);
        objectAssign(item, post);
        setFeedData(newFeedData);
    };

    const removeItem = (itemId: number, checkModel: boolean = true) => {
        setFeedData(
            feedData.filter((item) => {
                if (checkModel && !item.model) return true;
                const dataSrc = checkModel ? item.model : item;
                return dataSrc.id !== itemId;
            })
        );
    };

    const removeContentByUser = (userId: number, checkModel: boolean = true) => {
        setFeedData(
            feedData.filter((item) => {
                if (checkModel && !item.model) return true;
                const dataSrc = checkModel ? item.model.author : item;
                if (item.type === 'post') return dataSrc.id !== userId;
                else return true;
            })
        );
    };

    const renderItems = () => {
        const items: Array<React.ReactElement> = [];
        if (!feedData) return items;

        if (feedData.length === 0) {
            return renderEmptyItemsContainer();
        }

        const feedPageFooterData = tenantInfo;

        feedData.forEach((item, index) => {
            switch (item.type) {
                case 'page':
                    items.push(
                        <PagesItemType
                            isLastItem={index === feedData.length - 1}
                            item={item}
                            feedPageFooterData={feedPageFooterData}
                            setSharePage={setSharePage}
                            likePage={likePage}
                        />
                    );
                    break;
                case 'pn':
                    items.push(<PNSItemType isLastItem={index === feedData.length - 1} item={item} />);
                    break;
                case 'card':
                    items.push(<TYCardsItemType isLastItem={index === feedData.length - 1} item={item} tyBranding={tenantInfo.tyBranding} />);
                    break;
                case 'card-p2p':
                    items.push(
                        <SocialPostItemType
                            isLastItem={index === feedData.length - 1}
                            item={item}
                            setLiked={likeP2P}
                            setItem={updatePost}
                            removeItem={removeItem}
                            tyBranding={tenantInfo.tyBranding}
                            removeContentByUser={removeContentByUser}
                        />
                    );
                    break;
                case 'post':
                    items.push(
                        <SocialPostItemType
                            isLastItem={index === feedData.length - 1}
                            item={item}
                            setLiked={likePost}
                            setItem={updatePost}
                            removeItem={removeItem}
                            removeContentByUser={removeContentByUser}
                            tyBranding={tenantInfo.tyBranding}
                        />
                    );
                    break;
            }
        });
        return items;
    };

    const renderEmptyItemsContainer = () => {
        return (
            <React.Fragment>
                <Card className={`social-post-item p-3 pb-0 item-container ${userInfo.spEnabled ? 'no-data-container' : 'no-data-container-no-sp'} `}>
                    <span className={'no-data-content'}>{t('general.noData')}</span>
                </Card>
            </React.Fragment>
        );
    };

    const shareToUsers = (selectedUsers: Array<number>) => {
        const pageUrl = `${window.location.origin}/page/${pageToShare.model.id}`;
        const pageName = pageToShare.model.title;
        const messageStr = `${pageUrl} ${pageName}`;

        setSharePage(null);

        ThreadService.createThread(
            selectedUsers,
            messageStr,
            (resp: AxiosResponse) => {
                window.location.href = `/messages/thread/${resp.data.id}`;
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Thread Creation`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
            }
        );
    };

    const likeContent = (contentType: string, contentId: number, isLiked: boolean) => {
        const newFeedData = feedData ? [...feedData] : [];
        newFeedData.forEach((item) => {
            if (item.type !== contentType || !item.model || item.model.id != contentId) return;
            item.model.is_liked = isLiked;
            item.model.likes += isLiked ? 1 : -1;
        });
        setFeedData(newFeedData);
    };

    const likePage = (pageId: number, isLiked: boolean) => {
        likeContent('page', pageId, isLiked);
    };

    const likePost = (postId: number, isLiked: boolean) => {
        likeContent('post', postId, isLiked);
    };

    const likeP2P = (postId: number, isLiked: boolean) => {
        likeContent('card-p2p', postId, isLiked);
    };

    const updateFeedData = (newElem: Array<any>) => {
        setFeedData([...newElem, ...feedData]);
    };

    const appendFeedData = (newElem: Array<any>) => {
        setFeedData([...feedData, ...newElem]);
    };

    if (customLayoutFeed && fixedContainer) {
        return (
            <Box sx={{ height: '100%', position: 'relative', overflow: 'auto', maxWidth: '1200px', margin: '0 auto' }} onScroll={customPageScroll}>
                {userInfo.spEnabled && (
                    <CreateSocialPost
                        className={'p-1 mb-4 card create-social-post'}
                        userData={userData}
                        userInfo={userInfo}
                        feedDataMethod={updateFeedData}
                    />
                )}
                <Box>
                    {pageLoading && feedData.length === 0 ? (
                        <SpinnerLoad className='d-flex align-items-center justify-content-center mt-3' size={50} />
                    ) : (
                        renderItems()
                    )}
                </Box>
                {pageLoading && feedData.length > 0 && <SpinnerLoad className='d-flex align-items-center justify-content-center mt-3' size={50} />}
            </Box>
        );
    }

    if (customLayoutFeed && !fixedContainer) {
        return (
            <InfiniteScroll
                hasMore={hasMoreData}
                loader={<SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />}
                dataLength={feedData.length}
                next={loadMoreContentPages}
                scrollableTarget='page-frame-contents'
            >
                <Box sx={{ maxWidth: '1200px', margin: '0 auto' }}>
                    {userInfo.spEnabled && (
                        <CreateSocialPost
                            className={'p-1 mb-4 card create-social-post'}
                            userData={userData}
                            userInfo={userInfo}
                            feedDataMethod={updateFeedData}
                        />
                    )}
                    <Box>{!(pageLoading && feedData.length === 0) && renderItems()}</Box>
                </Box>
            </InfiniteScroll>
        );
    }

    if (layoutLoading) {
        return <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />;
    }

    if (config === 'custom') {
        return (
            <div data-testid='custom-layout' className='custom-layout'>
                <CustomLayout templateId={templateId} components={layoutContainers} />
            </div>
        );
    }

    return (
        <>
            {config === 'standard' && (
                <Container fluid className='h-100 p-0'>
                    {pageToShare !== null && (
                        <UsersListModal
                            title={t('feed.createThread')}
                            btnText={t('feed.done')}
                            isOpen={true}
                            toggle={() => setSharePage(null)}
                            doneCallback={shareToUsers}
                        />
                    )}
                    <Row className={'h-100'}>
                        <Col lg={3} xxl={2} className={'h-100 section profile-section'}>
                            <ProfileSection setUserData={setUserData} />
                        </Col>

                        <Col lg={6} xxl={7} className={'h-100 section feed-section'} style={{ paddingRight: '0.2rem' }}>
                            <Scrollbar onScroll={pageScroll} rightScroll={true} className='me-2' noScrollX>
                                {userInfo.spEnabled ? (
                                    <CreateSocialPost
                                        className={'p-1 mb-4 card create-social-post'}
                                        userData={userData}
                                        userInfo={userInfo}
                                        feedDataMethod={updateFeedData}
                                    />
                                ) : (
                                    ''
                                )}
                                <Box>
                                    {pageLoading && feedData.length === 0 ? (
                                        <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
                                    ) : (
                                        renderItems()
                                    )}
                                    {pageLoading && feedData.length > 0 && (
                                        <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
                                    )}
                                </Box>
                            </Scrollbar>
                        </Col>
                        <Col lg={3} className={'h-100 section comments-section'}>
                            {commentsLoading ? (
                                <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
                            ) : (
                                <RecentComments feedCommentList={commentsData} />
                            )}
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
};

export default FeedPage;
