export default {
  "please enter your company reference": "Bitte geben Sie Ihre Firmenreferenz ein",
  "enter your company reference": "Geben Sie Ihre Firmenreferenz ein",
  "please enter your user ID and password": "Bitte geben Sie Ihre Benutzer-ID und Ihr Passwort ein",
  "please enter user id": "Bitte geben Sie Ihre Benutzer-ID ein",
  "enter password": "Bitte gib dein Passwort ein",
  "forgot password": "Passwort vergessen",
  "errors": {
    "domain name can't be empty": "Domainname darf nicht leer sein",
    "user name can't be empty": "Der Benutzername darf nicht leer sein",
    "password can't be empty": "Passwort darf nicht leer sein",
    "incorrect user id or password": "Falsche Benutzer-ID oder falsches Passwort",
    "domain does not exist": "Domain '{{domain}}' existiert nicht",
    "account locked": "Es gab zu viele Versuche, das Konto ist jetzt gesperrt"
  },
  "hello": "Hallo",
  "log in": "Loggen Sie sich ein",
  "new user": "Neuer Nutzer",
  "create new account": "Neues Konto erstellen",
  "next": "Weiter",
  "verify": "VERIFIZIEREN",
  "contact support": "Support kontaktieren",
  "back": "ZURÜCK"
};