import React, { useRef, useState } from 'react';
import { Box, SxProps } from '@mui/material';
import DraftEditor from '../common/draftEditor/DraftEditor';
import { useTranslation } from 'react-i18next';
import { ChatBubbleReactions } from '../chatBubbleReactions/chatBubbleReactions';

export type SendMessagePanelProps = {
    sx?: SxProps;
    isResetEditorTextValue?: boolean;
    fileList: File[];
    editorFocus?: boolean;
    setEditorFocus?: React.Dispatch<React.SetStateAction<boolean>>;
    setFileList: React.Dispatch<React.SetStateAction<File[]>>;
    handleSubmit: (messageValue?: string, files?: File[]) => void;
    setEditorValue: React.Dispatch<React.SetStateAction<string>>;
    setIsResetEditorTextValue?: React.Dispatch<React.SetStateAction<boolean>>;
    setLinkPreviewDisplay?: React.Dispatch<React.SetStateAction<any>>;
};

export const SendMessagePanel = React.forwardRef((props: SendMessagePanelProps, ref) => {
    const { sx, isResetEditorTextValue, fileList, editorFocus, setEditorFocus, setFileList, handleSubmit, setEditorValue, setIsResetEditorTextValue, setLinkPreviewDisplay } =
        props;
    const { t } = useTranslation();
    const [isSubmitContent, setIsSubmitContent] = useState(false);
    const [emojiAnchorEl, setEmojiAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [selectedEmoji, setSelectedEmoji] = React.useState<any>(null);
    const attachFile = useRef<HTMLInputElement>(null);

    const open = Boolean(emojiAnchorEl);

    const sendMessage = (value: string) => {
        setIsSubmitContent(false);

        if ((value.length === 0 || value === '<p><br></p>') && fileList.length === 0) {
            return;
        }
        // Pass message value as object where contentValue = editorTextContent with block format, textValue = plain text with user id
        const message = value === '<p><br></p>' ? '' : value;
        handleSubmit(message, fileList);
        setFileList([]);
    };

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.target.files;
        if (files) {
            const filesArray = Array.from(files);
            setFileList((prevData) => [...prevData, ...filesArray]);
        }
    };

    const handleRemoveFile = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
        e.preventDefault();
        e.stopPropagation();
        const newFileList = [...fileList];
        newFileList.splice(index, 1);
        setFileList(newFileList);
        setEditorFocus && setEditorFocus(true);
    }

    const handleEmojiClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setEmojiAnchorEl(event.currentTarget);
    };
    
    const handleEmojiClose = () => {
        setEmojiAnchorEl(null);
        if (setEditorFocus) setEditorFocus(true);
    };

    const onEmojiClick = (emojiObject: any) => {
        setSelectedEmoji(emojiObject);
    };

    return (
        <Box sx={sx} ref={ref} data-testid="send-message-panel">
            <Box sx={{ display: 'flex', alignItems: 'center', mx: '10px' }}>
                <button className="unstyled-btn" data-testid="send-message-panel-attach-file-btn">
                    <span
                        className="fa-regular fa-paperclip font-24"
                        onClick={() => {
                            attachFile.current?.click();
                            setEditorFocus && setEditorFocus(true);
                        }}
                    >
                        <input
                            type="file"
                            ref={attachFile}
                            style={{ display: 'none' }}
                            onChange={handleFileSelect}
                            onClick={(e) => {
                                e.currentTarget.value = '';
                            }}
                            multiple={true}
                            data-testid="send-message-panel-attach-file-input"
                        />
                    </span>
                </button>
            </Box>
            <Box className={'message-editor-section'}>
                <DraftEditor
                    className="message-input"
                    isSubmitContent={isSubmitContent}
                    editorFocus={editorFocus}
                    setEditorFocus={setEditorFocus}
                    placeholder={`${t('chat.startTyping')}`}
                    handleEditorContentSubmit={sendMessage}
                    setEditorTextValue={setEditorValue}
                    handleRemoveFile={handleRemoveFile}
                    linkPreview={true}
                    setLinkPreviewDisplay={setLinkPreviewDisplay}
                    resetEditorTextValue={isResetEditorTextValue}
                    setIsResetEditorTextValue={setIsResetEditorTextValue}
                    filePreview={true}
                    filePreviewFiles={fileList}
                    selectedEmoji={selectedEmoji}
                    setSelectedEmoji={setSelectedEmoji}
                    allowSubmitOnEnter
                />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mx: '10px' }}>
                <ChatBubbleReactions 
                    id="send-message-panel-emoji-popover"
                    open={open}
                    displayReactions={true}
                    emojiAnchorEl={emojiAnchorEl}
                    handleEmojiClick={handleEmojiClick}
                    handleEmojiClose={handleEmojiClose}
                    onEmojiClick={onEmojiClick}
                    allowReactions={true}
                    isFromSendMessagePanel={true}
                    messageID=''
                    handleEllipsisClick={() => {}}
                    handleEllipsisClose={() => {}}
                    handleMessageDelete={() => {}}
                    ellipsisAnchorEl={null}
                    ellipsisId=''
                    openEllipsis={false}
                    primary
                />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }} data-testid="send-message-btn">
                <button className="unstyled-btn" type="submit" onClick={() => setIsSubmitContent(true)}>
                    <span className="fa-solid fa-paper-plane font-24" />
                </button>
            </Box>
        </Box>
    );
});
