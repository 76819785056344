import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
const renderHtml = require("html-react-parser");
import classNames from 'classnames';
import { NotificationItem } from '../../../types/types';
import notificationsService from '../../services/notificationsService';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';
import './notificationsListItem.scss';
import Slider from '../common/slider/slider';

type NotificationListItemProps = {
    item: NotificationItem;
    onReadChange?: Function;
}

const NotificationListItem: FunctionComponent<NotificationListItemProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { id, title, message, dateString, url, is_read } = props.item;
    const [isRead, setIsRead] = useState(is_read);
    useEffect(() => {
        setIsRead(props.item.is_read);
    }, [props]);

    const readSwitchHandler = (e: React.SyntheticEvent) => {
        const originalState = isRead;
        setIsRead(!originalState);
        notificationsService.markAsRead(id, !isRead, () => {
            if(props.onReadChange)
                props.onReadChange(id, !isRead);
        }, () => {
            dispatch(addNotification({ label: `Mark Notification`, text: t('general.errors.errorLoadingData'), type: 'danger' }));
            setIsRead(originalState);
        });
    }

    const clickHandler = () => {
        if(!url)
            return;

        const urlObj = new URL(url);
        if(urlObj.origin === window.location.origin)
            window.location.href = urlObj.pathname + urlObj.search;
        else
            window.location.href = url;
    }

    const messageStyle = {
        textOverflow: "ellipsis",
        WebkitLineClamp: 3,
        webkitBoxOrient: "vertical",
        overflow: "hidden",
        display: "-webkit-box"
    }

    return (
        <div data-testid="notification-item" className={classNames('d-flex align-items-center border-bottom', { 'bg-white-smoke': !isRead }, { 'c-pointer': url})}>
            <div data-testid="notification-section" className={'w-100 py-3 px-4 text-secondary text-break'} onClick={clickHandler}>
                <h5 className={'font-12'}>{ dateString }</h5>
                <h4 data-testid="notification-title" className={'font-weight-bold font-16 mb-0'}>{ title }</h4>
                <p data-testid="notification-message" className={'notification-message mb-0'} style={{...messageStyle, whiteSpace: "pre-line" }}>{ renderHtml(message) }</p>
            </div>

            <Slider className={'flex-shrink-1 me-4'} state={isRead}
                onChange={readSwitchHandler}
            />
        </div>
    );
}

export default NotificationListItem;