import React from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import defaultIcon from '../../../icons/no-result-widget-icon.svg';

export type NoResultsWidgetProps = {
    icon?: string;
    title: string;
    description?: string;
    containerSx?: SxProps;
    descriptionSx?: SxProps;
};

export const NoResultsWidget = (props: NoResultsWidgetProps) => {
    const { icon, title, description, containerSx, descriptionSx } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1rem',
                ...containerSx,
            }}
            data-testid="no-results-widget"
        >
            <Box>
                <Typography sx={{ fontSize: '22px', fontWeight: '600' }}>{title}</Typography>
            </Box>
            <Box>{icon ? <img src={props.icon} alt="No results found" /> : <img src={defaultIcon} alt="No results found" />}</Box>
            <Box sx={descriptionSx}>
                <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>{description}</Typography>
            </Box>
        </Box>
    );
};
