export default {
    "sharePost": "Começar partilha...",
    "what do you have to say": "O que você tem a dizer",
    "emptyField": "Não é possível gravar a mensagem vazia.",
    "socialPost": "Publicação Social",
    "postedFail": "Publicação Social um erro na transferência do ficheiro, por favor tente mais tarde.",
    "mediaFail": "Houve um erro na transferência do ficheiro, por favor tente mais tarde.",
    "close": "Fechar",
    "mediaTooBig": "O tamanho dos ficheiros excedeu o limite máximo para upload. O tamanho máximo é de ",
    "mentionUsers": "Mencionar usuários",
    "mentionUsersFail": "Não foi possível buscar os usuários mencionados. Tente novamente mais tarde.",
    "userUnavailable": "Usuário indisponível"
};