import React from 'react';
import { Grid, Box, Skeleton, Tooltip, Breadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import './searchCard.scss';
import moment from 'moment';
const renderHtml = require('html-react-parser');

export type SearchCardProps = {
    loading?: boolean;
    imgSrc?: string;
    imgAlt?: string;
    title?: string;
    subtitle?: { label: string; url: string }[];
    description?: string;
    publisher?: { name: string; image: string };
    published_at?: string;
    tags?: string[];
    handleClick?: () => void;
};

export const SearchCard = (props: SearchCardProps) => {
    const { loading, imgSrc, imgAlt, title, subtitle, description, publisher, published_at, tags, handleClick } = props;

    if (loading) {
        return (
            <Grid container spacing={1} className='image-card-container' data-testid='search-card-loading'>
                <Grid item xs={2} className='image-card-img' sx={{ padding: '0px !imporatant' }}>
                    <Skeleton variant='rectangular' animation='wave' sx={{ height: '100%' }} />
                </Grid>
                <Grid xs={10} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', padding: '10px' }}>
                    <Box sx={{ width: '300px' }}>
                        <Skeleton variant='text' animation='wave' />
                        <Skeleton variant='text' animation='wave' width='60%' />
                        <Skeleton variant='rectangular' animation='wave' sx={{ marginTop: '10px' }} height='60px' />
                    </Box>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid
            container
            spacing={1}
            className='image-card-container'
            sx={{ margin: 0, width: '100%', backgroundColor: 'white', cursor: 'pointer' }}
            data-testid='search-card'
            onClick={handleClick}
        >
            <Grid item xs={2} className='image-card-img'>
                {imgSrc ? (
                    <img src={imgSrc as string} alt={imgAlt as string} />
                ) : (
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'lightgrey',
                            borderTopLeftRadius: '10px',
                            borderBottomLeftRadius: '10px',
                        }}
                    >
                        <Tooltip title='No image found'>
                            <i className='fa-regular fa-image-slash' style={{ fontSize: '50px', color: 'black', fontStyle: 'normal' }} />
                        </Tooltip>
                    </Box>
                )}
            </Grid>
            <Grid xs={10} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', padding: '10px', borderRadius: '10px' }}>
                <Box>
                    <Typography sx={{ fontSize: '18px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {renderHtml(title)}
                    </Typography>
                    {subtitle && (
                        <Breadcrumbs separator='>' aria-label='breadcrumb' sx={{ paddingTop: '2px' }}>
                            {subtitle.map((item: any, index: number) => (
                                <Link
                                    component={RouterLink}
                                    underline='hover'
                                    color='inherit'
                                    to={item.url}
                                    key={index}
                                    sx={{ fontSize: '12px' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    {item.label}
                                </Link>
                            ))}
                        </Breadcrumbs>
                    )}
                    <Typography className='description' sx={{ fontSize: '15px', marginTop: '8px' }}>
                        {renderHtml(description)}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', gap: '25px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={publisher?.image} alt={publisher?.name} style={{ height: '25px', width: '25px', marginRight: '10px' }} />
                            <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>{publisher?.name}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <span className='fa-thin fa-calendar-days' style={{ fontSize: '1.5em', width: '30px', height: '25px' }} />
                            <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>{moment(published_at).format('DD/MM/YYYY')}</Typography>
                        </Box>
                    </Box>
                    {tags && tags[0] !== '' && (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '10px',
                                margin: '0 10px',
                                overflow: 'hidden',
                                overflowX: 'auto',
                                justifyContent: 'flex-start',
                                scrollbarWidth: 'thin',
                            }}
                        >
                            {tags.map((tag, index) => (
                                <Box key={index} sx={{ backgroundColor: '#E0E0E0', borderRadius: '8px', padding: '5px 10px', fontSize: '12px' }}>
                                    #{tag}
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};
