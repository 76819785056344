export default {
  "sharePost": "Fangen Sie an zu teilen...",
  "what do you have to say": "Was hast du zu sagen",
  "emptyField": "Ein leerer Beitrag in sozialen Netzwerken kann nicht gespeichert werden.",
  "socialPost": "Sozialer Beitrag",
  "postedFail": "Der Beitrag in sozialen Netzwerken konnte nicht veröffentlicht werden. Bitte versuchen Sie es später erneut.",
  "mediaFail": "Beim Hochladen der Medien ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
  "close": "Schliessen",
  "mediaTooBig": "Einige Dateien haben die maximale Größe überschritten und konnten nicht hochgeladen werden. Die maximale Dateigröße ist",
  "mentionUsers": "Nutzer erwähnen",
  "mentionUsersFail": "Die erwähnten Benutzer konnten nicht abgerufen werden. Bitte versuchen Sie es später erneut.",
  "userUnavailable": "Benutzer nicht verfügbar"
};