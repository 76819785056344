import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import { Modal, ModalHeader } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import connectService from "./../../../services/connectService";
import { AxiosResponse, AxiosError } from "axios";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { useDebounceEffect } from "./useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import "./imageModal.scss";
import Spinner from "../../../../web/components/common/spinnerLoad/spinnerLoad";

interface ImageModalProps {
  isOpen: any;
  setIsOpen?: any;
  imageUrl: string;
  setImageUploaded: Function;
  headerName: string;
  type: string;
  ratio: number;
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ImageModalComponent: FunctionComponent<ImageModalProps> = ({
  isOpen,
  setIsOpen,
  imageUrl,
  setImageUploaded,
  headerName,
  type,
  ratio,
}) => {
  const { t } = useTranslation();
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(100);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(ratio);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setImgSrc(reader.result != null ? reader.result.toString() : "");
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop != undefined &&
        completedCrop.width &&
        completedCrop.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(ratio);
      setCrop(centerAspectCrop(width, height, ratio));
    }
  }

  const setVisibility = () => {
    setIsOpen(false);
  };

  const escHandle = useCallback((event: { keyCode: number; }) => {
    if (event.keyCode === 27) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    setImgSrc(imageUrl);
    document.addEventListener("keydown", escHandle, false);
    return () => {
      document.addEventListener("keydown", escHandle, false);
    };
  }, []);

  const handleSubmit = () => {
    if (previewCanvasRef.current != undefined) {
      setIsLoading(true);
      const blobBin = atob(previewCanvasRef.current.toDataURL().split(",")[1]);
      const array = [];
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      const file = new Blob([new Uint8Array(array)], { type: "image/png" });

      let formdata = new FormData();
      formdata.append("image", file);

      if (type === "image") {
        connectService.saveProfileImage(
          formdata,
          (resp: AxiosResponse) => {
            setImageUploaded(resp?.data?.url);
            setVisibility();
            setIsLoading(false);
          },
          (err: AxiosError) => {
            console.log("err", err);
          }
        );
      } else {
        connectService.saveProfileHeaderImage(
          formdata,
          (resp: AxiosResponse) => {
            setImageUploaded(resp?.data?.url);
            setVisibility();
            setIsLoading(false);
          },
          (err: AxiosError) => {
            console.log("err", err);
          }
        );
      }
    }
  };

  return isOpen ? (
    <Modal isOpen={isOpen} className={classnames("files-list-modal")}>
      <ModalHeader>
        <h5 className="header-name">{headerName}</h5>
        <button
          type="button"
          className="close header-button"
          data-dismiss="modal"
          aria-label="Close"
          id="close-awards-list"
          onClick={setVisibility}
        >
          <span aria-hidden="true" className="fa-thin fa-xmark" />
        </button>
      </ModalHeader>

      <div className="modal-body w-100">
        <div
          className="d-flex align-items-center mb-2"
          style={{ justifyContent: "space-between" }}
        >
          <div className="col-9 mb-2">
            <input
              type="text"
              value=""
              className="w-100 form-control file-name"
              readOnly
            />
          </div>
          <div className="col-3 mb-2 ml-30">
            <label className="mb0 text-primary">
              <input
                type="file"
                className="d-none file-upload"
                accept="image/*"
                onChange={onSelectFile}
              />
              <span className="upload-file">{t("connect.uploadFile")}</span>
            </label>
          </div>
        </div>
        <div className="d-flex">
          <div className="mb-2 text-secondary inline-inputs">
            <label htmlFor="scale-input text-secondary">
              {t("connect.zoom")}
            </label>
            <input
              id="scale-input"
              type="number"
              step="1"
              value={scale}
              disabled={!imgSrc}
              className="form-control text-secondary"
              onChange={(e) => setScale(Number(e.target.value))}
            />
          </div>
          <div className="mb-2 text-secondary inline-inputs rotate-input-properties">
            <label htmlFor="rotate-input text-secondary">
              {t("connect.rotate")}{" "}
            </label>
            <input
              id="rotate-input"
              className="form-control text-secondary"
              type="number"
              value={rotate}
              disabled={!imgSrc}
              onChange={(e) =>
                setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
              }
            />
          </div>
        </div>
        <div className="mb-2">
          <label className="text-secondary ml-2 me-2">
            {t("connect.ratio")}{" "}
          </label>
          <label className="switch mt-sm">
            <input
              type="checkbox"
              className="slider-checkbox"
              checked={!!aspect}
              onClick={handleToggleAspectClick}
            />
            <span className="switch-slider round" />
          </label>
        </div>
        <div className="my-picture mb-2 w-100 image-centered">
          {imgSrc !== "" && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              className="image-centered"
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}%) rotate(${rotate}deg)` }}
                className="image-centered"
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </div>
        <div className="mb-2 d-none">
          {completedCrop !== undefined && (
            <canvas ref={previewCanvasRef} className="canvas-preview" />
          )}
        </div>
        <div className="modal-footer p-0 d-block">
          {isLoading ? (
            <div className="d-flex align-items-center justify-content-center h-100 mt-3">
              <Spinner />
            </div>
          ) : (
            <button
              type="button"
              className="btn btn-block btn-success bg-engage-green w-100 mt-3 text-white"
              id="save-profile-image"
              onClick={handleSubmit}
            >
              {t("connect.done")}
            </button>
          )}
        </div>
      </div>
    </Modal>
  ) : null;
};

export default ImageModalComponent;
